import React from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';

import { decode } from 'html-entities';

import { getUrl, formatDate } from './../planner/planner';

class EditScrapForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			scrap: [],
			isLoaded: false,
			reason: '',
			quantity: 1,
			materials: [],
			locked: false,
			operators: [],
			operator: null,
			traveller: null,
			traveller_number: null,
			material: null,
			toast: React.createRef(),
			status: null,
			fault: null
		}
	}

	/**
	 * Runs when the component mounts
	 */
	async componentDidMount() {
		const [getScrapDetails] = await Promise.all([
			axios.get(getUrl() + '/wp-json/planner/v1/scraps/' + this.props.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
		]);

		let scrapCategoryList = [];
		let statusCategoryList = [];

		Object.keys(getScrapDetails.data.scrap_statuses).map((key) => (
			statusCategoryList[key] = getScrapDetails.data.scrap_statuses[key]
		));

		this.setState({
			scrap: getScrapDetails.data.scrap,
			locked: getScrapDetails.data.scrap.locked,
			reason: getScrapDetails.data.scrap.issue,
			quantity: getScrapDetails.data.scrap.quantity,
			operators: getScrapDetails.data.scrap_authors,
			scrap_categories: getScrapDetails.data.scrap_categories,
			status_categories: statusCategoryList,
			material: getScrapDetails.data.scrap.material_id,
			materialTitle: getScrapDetails.data.scrap.material_description,
			traveller: getScrapDetails.data.scrap.traveller_id,
			traveller_number: getScrapDetails.data.scrap.traveller_number,
			isLoaded: true,
			status: getScrapDetails.data.scrap.status_name,
			fault: getScrapDetails.data.scrap.scrap_cat_id,
			operator: Number(getScrapDetails.data.scrap.reported_by_user_id)
		});

		document.title = 'Scrap #' + getScrapDetails.data.scrap.scrap_number + ' - Planner';

		setInterval(() => {
			this.lockScrap(getScrapDetails);
		}, 15000);

		this.lockScrap(getScrapDetails);
	}

	/**
	 * Lock the scrap to the ID that is editing
	 * 
	 * @param {Object} scrapDetails The data for the current scrap
	 */
	lockScrap(scrapDetails) {
		if(scrapDetails.data.locked === false) {
			axios.put(getUrl() + '/wp-json/planner/v1/scraps/' + this.props.id, {
				"locked" : Math.floor(Date.now() / 1000) + ':' + this.props.currentLoggedInUser.id
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then(res => this.setState({
				isLoaded: true
			})).catch(err => console.log(err))
		}
	}

	/**
	 * Checks if the scrap is being edited by another user
	 * 
	 * @returns {Boolean} Returns true or false depending if the scrap is currently locked
	 */
	isScrapLocked() {
		if((this.state.locked === false || this.props.currentLoggedInUser.id === this.state.locked.ID)) {
			return false;
		} else {
			return true;
		}
	}

	optionsTemplate(e) {
		if (e) {
			return (
				<div className="flex align-items-center">
					<div>{decode(e.title)}</div>
				</div>
			);
		}

		return '';
	}

	optionTemplate(e) {
		return (
			<div className="flex align-items-center">
				<div>{decode(e.title)}</div>
			</div>
		);
	}

	statusUpdate(e) {
		this.setState({status: e})

		axios.put(getUrl() + '/wp-json/planner/v1/scraps/' + this.props.id, {
			"status" : e
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err))

		this.state.toast.current.show({ severity: 'success', summary: 'Scrap', detail: 'Status Updated.' });
	}

	faultUpdate(e) {
		this.setState({fault: e})

		axios.put(getUrl() + '/wp-json/planner/v1/scraps/' + this.props.id, {
			"scrap_cat_id" : e
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err))

		this.state.toast.current.show({ severity: 'success', summary: 'Scrap', detail: 'Fault Updated.' });
	}

	operatorUpdate(e) {
		this.setState({operator: e})

		axios.put(getUrl() + '/wp-json/planner/v1/scraps/' + this.props.id, {
			"reported_by_user_id" : e
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err))

		this.state.toast.current.show({ severity: 'success', summary: 'Scrap', detail: 'Operator Updated.' });
	}

	reasonUpdate(e) {
		this.setState({reason: e.target.value});

		axios.put(getUrl() + '/wp-json/planner/v1/scraps/' + this.props.id, {
			"issue" : e.target.value
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err))

		this.state.toast.current.show({ severity: 'success', summary: 'Scrap', detail: 'Reason Updated.' });
	}

	fieldsDisabled() {
		if(this.props.capabilities.length != 0 && this.props.capabilities.edit_scraps) {
			return false;
		} else {
			return true;
		}
 	}

	render() {
		const { isLoaded, scrap, toast, reason, traveller_number, operators, scrap_categories, status_categories } = this.state;

		return (
			<>
				<Toast ref={toast} position="bottom-right" />
				<div className="edit-order-bar position-sticky top-0 p-3">
					<div className="container px-0 d-flex justify-content-between align-items-center">
						<div className="d-flex justify-content-start align-items-center">
							<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.onSidebarOpen(true);}}>
								<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
							</Button>
							<div>
								{
									isLoaded && (<>
										<h1 className="mb-0 h3">Editing Scrap: #{scrap.scrap_number}</h1>
										<strong>Date Reported:</strong>&nbsp;{formatDate(scrap.date_reported, true)}
									</>)
								}
							</div>
							{
								!isLoaded && (
									<div>
										<i className="pi pi-spin pi-spinner" style={{ fontSize: '2.8rem' }}></i>
									</div>
								)
							}
						</div>
						<div>
							{
								this.isScrapLocked() && (
									<p className="d-inline mb-0 ms-3"><span className="badge bg-danger d-inline-flex align-items-center display-6"><i className="pi pi-lock me-2"></i> {this.state.locked.data.user_login} is currently editing this scrap</span></p>
								)
							}
						</div>
					</div>
				</div>
				{
					isLoaded && ( // https://stackoverflow.com/questions/70682832/
					<>
						<form className="container px-4 px-xxl-0 mt-5">
							<div className="col-12 pt-2 mb-5">
								<Fieldset legend="Scrap">
									<div className="container">
										<div className="row mb-3">
											<div className="col-12 col-md-4">
												<strong className="w-100 mb-1 d-block">Reported By:</strong>
												<Dropdown value={this.state.operator} onChange={(e) => this.operatorUpdate(e.value)} disabled={this.fieldsDisabled()} options={operators} optionLabel="display_name" optionValue="id" placeholder="Select an operator" className="w-full md:w-14rem" />
											</div>
											<div className="col-12 col-md-4">
												<strong className="w-100 mb-1 d-block">Status:</strong>
												<Dropdown value={this.state.status} onChange={(e) => this.statusUpdate(e.value)} disabled={this.fieldsDisabled()} options={status_categories} optionLabel="label" optionValue="name" placeholder="Select a Status" className="w-full md:w-14rem" />
											</div>
											<div className="col-12 col-md-4">
												<strong className="w-100 mb-1 d-block">Fault:</strong>
												<Dropdown value={this.state.fault} onChange={(e) => this.faultUpdate(e.value)} disabled={this.fieldsDisabled()} options={scrap_categories} optionLabel="name" optionValue="id" placeholder="Select a Fault" className="w-full md:w-14rem" />
											</div>
											{
												scrap.date_replaced && this.state.status == 'planner-replaced' && (
													<div className="col-12 col-md-3 mt-3">
														<strong className="w-100 mb-1 d-block">Date Replaced:</strong>
														{
															formatDate(scrap.date_replaced, true)
														}
													</div>
												)
											}
										</div>
										<div className="col-12">
											<strong className="w-100 mb-1 d-block">Reason:</strong>
											<InputTextarea value={reason} onChange={(e) => this.setState({reason: e.target.value})} onBlur={(e) => this.reasonUpdate(e)} rows={8} cols={30} disabled={this.fieldsDisabled()} />
										</div>
									</div>
								</Fieldset>
							</div>
							<div className="row">
								<div className="col-12">
									<Fieldset legend="Traveller">
										<div className="container">
											<div className="row">
											<div className="col-12 col-md-4">
													<strong className="w-100 mb-1 d-block">Traveller:</strong>
													<Link to={"/traveller/" + scrap.traveller_id}>#{traveller_number}</Link>
												</div>
												<div className="col-12 col-md-4">
													<strong className="w-100 mb-1 d-block">Order:</strong>
													<Link to={"/job/" + scrap.order_id}>#{scrap.order_number}</Link>
												</div>
												<div className="col-12 col-md-4">
													<strong className="w-100 mb-1 d-block">Product:</strong>
													<Link className="product-description" data-pr-tooltip={scrap.product_description} data-pr-position="left" to={"/product/" + scrap.product_id}>{scrap.product_sku}</Link> <span className="badge bg-primary text-white">x {scrap.product_qty}</span>
													<Tooltip autoHide={false} target=".product-description" />
												</div>
											</div>
										</div>
									</Fieldset>
								</div>
								<div className="col-12 my-5">
									<Fieldset legend="Material">
										<div className="container">
											<div className="row">
												<div className="col-12 col-md-4">
													<strong className="w-100 mb-1 d-block">Material:</strong>
													<span><Link className="material-description" data-pr-tooltip={scrap.material_description} data-pr-position="left" to={"/product/" + scrap.material_id}>{scrap.material_sku}</Link> <span className="badge bg-primary text-white">x {scrap.material_qty}</span></span>
													<Tooltip autoHide={false} target=".material-description" />
												</div>
												<div className="col-12 col-md-4 mt-2 mt-xxl-0">
													<strong className="w-100 mb-1 d-block">Average Cost Price:</strong>
													{decode(scrap.material_average_cost_price)}
												</div>
												<div className="col-12 col-md-4 mt-2 mt-xxl-0">
													<strong className="w-100 mb-1 d-block">Total Cost Price:</strong>
													{decode(scrap.material_total_cost_price)}
												</div>
											</div>
										</div>
									</Fieldset>
								</div>
							</div>
						</form> 
					</>
				)
			}
			</>
    );
  }
}
export default EditScrapForm;