import React, { useState, useEffect } from 'react';
import { Menu } from 'primereact/menu';
import { ScrollPanel } from 'primereact/scrollpanel';
import { useHistory } from "react-router-dom";
import { Badge } from 'primereact/badge';
import { useLocation } from 'react-router';
import axios from 'axios';
import { getUrl } from '../planner/planner';

export default function Navigation(props) {
	var navigate = useHistory();
	const [isLoaded, setIsLoaded] = useState(true);
	const [navigation, setNavigation] = useState([]);
	const [statusCount, setStatusCount] = useState({});
	const location = useLocation();
	const [refresh, setRefresh] = useState(false);

	/**
	 * Check if the current location matches the path specified to enable active states
	 * 
	 * @param {String} path The path which will be used to check 
	 * @returns {String} Return the active class if the path matches the current location
	 */
	function isActive(path, secondPath) {	
		if(navigate.location.pathname === path || navigate.location.pathname.includes(secondPath)) {
			return 'p-menuitem-active';
		}
	}

	useEffect(() => {
		var salesOrdersCount = null;
		var inProductionCount = null;
		var shipCount = null;
		var onHoldCount = null;
		var kittedCount = null;
		var priority = null;
		var TravellerPreparedCount = null;
	
		if(props.menuItems.orders) {
			props.menuItems.orders.forEach(status => {
				if(status.name === 'Sales Orders') {
					salesOrdersCount = status.count;
				}

				if(status.name === 'In Production') {
					TravellerPreparedCount = status.count;
				}

				if(status.name === 'Kitted') {
					kittedCount = status.count;
				}

				if(status.name === 'Priority') {
					priority = status.count;
				}

				if(status.name === 'Ready to Ship') {
					shipCount = status.count;
				}

				if(status.name === 'On Hold') {
					onHoldCount = status.count;
				}
			});
		}

		let travellerInputted = null;
		let travellerPrepared = null;
		let travellerinProgress = null;
		let scraps = null;
		let suggestions = null;
		let travellerBooking = null;

		if(props.menuItems.travellers) {
			props.menuItems.travellers.forEach(status => {
				if(status.name === 'Inputted') {
					travellerInputted = status.count;
				}
				if(status.name === 'Prepared') {
					travellerPrepared = status.count;
				}
				if(status.name === 'In Progress') {
					travellerinProgress = status.count;
				}
			});
		}

		if(props.menuItems.suggestions) {
			suggestions = 0;

			props.menuItems.suggestions.forEach(status => {
				if(status.count) {
					suggestions = suggestions + status.count;
				}
			});
		}

		if(props.menuItems.scraps) {
			scraps = 0;

			props.menuItems.scraps.forEach(status => {
				if(status.count) {
					scraps = scraps + status.count;
				}
			});
		}

		if(props.menuItems.bookings) {
			travellerBooking = 0;

			props.menuItems.bookings.forEach(status => {
				if(status.name === 'Travellers') {
					travellerBooking = status.count;
				}
			});
		}

		let jobs = {
			label: 'Jobs',
			items: [
				{
					label: 'Sales Orders',
					icon: 'pi pi-shopping-cart',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-shopping-cart me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span>
									{
										isLoaded && salesOrdersCount > 0 && (
											<Badge className="ms-2" value={salesOrdersCount} />
										)
									}
								</div>
							</>
						)
					},
					command: () => {
						navigate.push('/jobs/sales-order')
						props.onSidebarClose(true)
						props.onSidebarRefresh(true)
						setRefresh(true);
					},
					className: isActive('/jobs/sales-order', '/job/', false)
				},
				{
					label: 'On Hold',
					icon: 'pi pi-ban',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-ban me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span>
									{
										isLoaded && onHoldCount > 0 && (
											<Badge className="ms-2" value={onHoldCount} />
										)
									}
								</div>
							</>
						)
					},
					command:  () => {
						navigate.push('/jobs/on-hold')
						props.onSidebarClose(true)
						props.onSidebarRefresh(true)
					},
					className: isActive('/jobs/on-hold')
				},
				{
					label: 'Priority',
					icon: 'pi pi-exclamation-circle',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-exclamation-circle me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span>
									{
										isLoaded && priority > 0 && (
											<Badge className="ms-2" value={priority} />
										)
									}
								</div>
							</>
						)
					},
					command: () => {
						navigate.push('/jobs/priority')
						props.onSidebarClose(true)
						props.onSidebarRefresh(true)
						setRefresh(true);
					},
					className: isActive('/jobs/priority')
				},
				{
					label: 'Kitted',
					icon: 'pi pi-cart-plus',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-cart-plus me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span>
									{
										isLoaded && kittedCount > 0 && (
											<Badge className="ms-2" value={kittedCount} />
										)
									}
								</div>
							</>
						)
					},
					command:  () => {
						navigate.push('/jobs/kitted')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/jobs/kitted')
				},
				{
					label: 'In Production',
					icon: 'pi pi-wrench',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-wrench me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span>
									{
										isLoaded && TravellerPreparedCount > 0 && (
											<Badge className="ms-2" value={TravellerPreparedCount} />
										)
									}
								</div>
							</>
						)
					},
					command:  () => {
						navigate.push('/jobs/in-production')
						props.onSidebarClose(true)
						props.onSidebarRefresh(true)
						setRefresh(true);
					},
					className: isActive('/jobs/in-production')
				},
				{
					label: 'Ready to Ship',
					icon: 'pi pi-truck',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-truck me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span> 
									{
										isLoaded && shipCount > 0 && (
											<Badge className="ms-2" value={shipCount} /> 
										)
									}
								</div>
							</>
						)
					},
					command: () => {
						navigate.push('/jobs/ready-to-ship')
						props.onSidebarClose(true)
						props.onSidebarRefresh(true)
						setRefresh(true);
					},
					className: isActive('/jobs/ready-to-ship')
				},
				{
					label: 'Completed',
					icon: 'pi pi-check',
					command:  () => {
						navigate.push('/jobs/completed')
						props.onSidebarClose(true)
						props.onSidebarRefresh(true)
						setRefresh(true);
					},
					className: isActive('/jobs/completed')
				},
				{
					label: 'Cancelled',
					icon: 'pi pi-times',
					command:  () => {
						navigate.push('/jobs/cancelled')
						props.onSidebarClose(true)
						props.onSidebarRefresh(true)
						setRefresh(true);
					},
					className: isActive('/jobs/cancelled')
				},
				{
					label: 'Pro-Formas',
					icon: 'pi pi-file',
					command: () => {
						navigate.push('/jobs/pro-forma')
						props.onSidebarClose(true)
						props.onSidebarRefresh(true)
						setRefresh(true);
					},
					className: isActive('/jobs/pro-forma')
				}
			]
		};


		let companyItems = [];

		if(props.companyMenuItems !== undefined) {
			props.companyMenuItems.forEach(res => {
				companyItems.push({
					label: res.name,
					icon: 'pi pi-building',
					command: () => {
						navigate.push('/companies/' + res.id)
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/companies/' + res.id)
				});
			});
		}

		let customerItems = [];

		if(props.customerMenuItems !== undefined) {
			props.customerMenuItems.forEach(res => {
				customerItems.push({
					label: res.name,
					icon: 'pi pi-globe',
					command:  () => {
						navigate.push('/customers/' + res.id)
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/customers/' + res.id)
				});
			});
		}

		let forcast = {
			label: 'Bookings',
			items: []
		}

		if(props.capabilities.read_bookings_employees) {
			forcast.items.push(
				{
					label: 'Employees',
					icon: 'pi pi-calendar',
					command:  () => {
						navigate.push('/bookings/employees')
						props.onSidebarClose(true)
					},
					className: isActive('/bookings/employees')
				}
			)
		}

		if(props.capabilities.read_bookings_travellers) {
			forcast.items.push(
				{
					label: 'Travellers',
					icon: 'pi pi-calendar',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-calendar me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span>
									{
										isLoaded && Number(travellerBooking) > 0 && (
											<Badge className="ms-2" value={Number(travellerBooking)} />
										)
									}
								</div>
							</>
						)
					},
					command:  () => {
						navigate.push('/bookings/travellers')
						props.onSidebarClose(true)
					},
					className: isActive('/bookings/travellers')
				},
			)
		}

		let customers = {
			label: 'Customers',
			items: customerItems
		}

		let companies = {
			label: 'Companies',
			items: companyItems
		}

		let products = {
			label: 'Products',
			items: [
				{
					label: 'All Products',
					icon: 'pi pi-box',
					command: () => {
						navigate.push('/products')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/products', '/product/', false)
				},
				{
					label: 'Battery Packs',
					icon: 'pi pi-bolt',
					command: () => {
						navigate.push('/products/battery-packs')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/products/battery-packs')
				},
				{
					label: 'Cells',
					icon: 'pi pi-database',
					command: () => {
						navigate.push('/products/cells')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/products/cells')
				},
				{
					label: 'Components', 
					icon: 'pi pi-cog',
					command: () => {
						navigate.push('/products/components')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/products/components')
				},
				{
					label: 'Chargers',
					icon: 'pi pi-times-circle',
					command: () => {
						navigate.push('/products/chargers')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/products/chargers')
				},
				{
					label: 'Accessories',
					icon: 'pi pi-th-large',
					command: () => {
						navigate.push('/products/accessories')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/products/accessories')
				},
				{
					label: 'Uncategorised',
					icon: 'pi pi-exclamation-triangle',
					command: () => {
						navigate.push('/products/uncategorised')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/products/uncategorised')
				},
			]
		}

		let reports = {
			label: 'Reports',
			items: [
				{
					label: 'Order',
					icon: 'pi pi-chart-line',
					command:  () => {
						navigate.push('/reports/orders')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/reports/orders')
				},
				{
					label: 'Product',
					icon: 'pi pi-chart-bar',
					command:  () => {
						navigate.push('/reports/products')
						props.onSidebarClose(true)
					},
					className: isActive('/reports/products')
				},
				{
					label: 'Employee',
					icon: 'pi pi-chart-pie',
					command:  () => {
						navigate.push('/reports/employees')
						props.onSidebarClose(true)
					},
					className: isActive('/reports/employees')
				}
			]
		}

		let travellers = {
			label: 'Travellers',
			items: [
				{
					label: 'Inputted',
					icon: 'pi pi-pencil',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-pencil me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span>
									{
										isLoaded && Number(travellerInputted) > 0 && (
											<Badge className="ms-2" value={Number(travellerInputted)} />
										)
									}
								</div>
							</>
						)
					},
					command:  () => {
						navigate.push('/travellers/inputted')
						props.onSidebarClose(true)
					},
					className: isActive('/travellers/inputted')
				},
				{
					label: 'Prepared',
					icon: 'pi pi-tablet',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-tablet me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span>
									{
										isLoaded && Number(travellerPrepared) > 0 && (
											<Badge className="ms-2" value={Number(travellerPrepared)} />
										)
									}
								</div>
							</>
						)
					},
					command:  () => {
						navigate.push('/travellers/prepared')
						props.onSidebarClose(true)
					},
					className: isActive('/travellers/prepared')
				},
				{
					label: 'In Progress',
					icon: 'pi pi-wrench',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-wrench me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span>
									{
										isLoaded && Number(travellerinProgress) > 0 && (
											<Badge className="ms-2" value={Number(travellerinProgress)} />
										)
									}
								</div>
							</>
						)
					},
					command:  () => {
						navigate.push('/travellers/in-progress')
						props.onSidebarClose(true)
					},
					className: isActive('/travellers/in-progress')
				},
				{
					label: 'On Hold',
					icon: 'pi pi-ban',
					command:  () => {
						navigate.push('/travellers/on-hold')
						props.onSidebarClose(true)
					},
					className: isActive('/travellers/on-hold')
				},
				{
					label: 'Cancelled',
					icon: 'pi pi-times',
					command:  () => {
						navigate.push('/travellers/cancelled')
						props.onSidebarClose(true)
					},
					className: isActive('/travellers/cancelled')
				},
				{
					label: 'Completed',
					icon: 'pi pi-check',
					command:  () => {
						navigate.push('/travellers/completed')
						props.onSidebarClose(true)
					},
					className: isActive('/travellers/completed')
				}
			]
		}
	
		if(props.capabilities.read_processes == true) {
			products.items.push({
				label: 'Processes',
				icon: 'pi pi-stopwatch',
				command:  () => {
					navigate.push('/processes')
					props.onSidebarClose(true)
				},
				className: isActive('/processes')
			});
		}
	
		let employees = {
			label: 'Employees',
			items: [
				{
					label: 'Account Managers',
					icon: 'pi pi-user',
					command:  () => {
						navigate.push('/employees/account-manager')
						props.onSidebarClose(true)
					},
					className: isActive('/employees/account-manager')
				},
				{
					label: 'Production Managers',
					icon: 'pi pi-user',
					command:  () => {
						navigate.push('/employees/production-manager')
						props.onSidebarClose(true)
					},
					className: isActive('/employees/production-manager')
				},
				{
					label: 'Production Operators',
					icon: 'pi pi-user',
					command:  () => {
						navigate.push('/employees/production-operator')
						props.onSidebarClose(true)
					},
					className: isActive('/employees/production-operator')
				}
			]
		}

		let displayName = '';

		if(props.user.name != undefined) {
			if(props.user.name.slice(-1) === 's') {
				displayName = props.user.name + '\'';
			} else {
				displayName = props.user.name + '\'s';
			}
		}

		let accountItems = [];

		//if(props.capabilities.can_sign_out) {
			accountItems = [
				{
					label: 'Sign Out',
					icon: 'pi pi-sign-out',
					command:  () => {
						localStorage.removeItem("userCapabilities");
						localStorage.removeItem("userToken");

						setNavigation([])
						props.onLogout(true)
					}
				}
			]
		//}

		let account = {
			label: displayName + ' Account',
			items: accountItems,
		};

		let scrapSection = {
			label: 'Scraps',
			items: [
				{
					label: 'Reported',
					icon: 'pi pi-trash',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-trash me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span>
									{
										isLoaded && Number(scraps) > 0 && (
											<Badge className="ms-2" value={Number(scraps)} />
										)
									}
								</div>
							</>
						)
					},
					command: () => {
						navigate.push('/scraps/reported')
						props.onSidebarClose(true)
					},
					className: isActive('/scraps/reported')
				},
				{
					label: 'Replaced',
					icon: 'pi pi-refresh',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-refresh me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span>
								</div>
							</>
						)
					},
					command: () => {
						navigate.push('/scraps/replaced')
						props.onSidebarClose(true)
					},
					className: isActive('/scraps/replaced')
				}
			]
		}

		let suggestionsSection = {
			label: 'Suggestions',
			items: [
				{
					label: 'Reported',
					icon: 'pi pi-comments',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-comments me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span>
									{
										isLoaded && Number(suggestions) > 0 && (
											<Badge className="ms-2" value={Number(suggestions)} />
										)
									}
								</div>
							</>
						)
					},
					command:  () => {
						navigate.push('/suggestions/reported')
						props.onSidebarClose(true)
					},
					className: isActive('/suggestions/reported')
				},
				{
					label: 'Approved',
					icon: 'pi pi-thumbs-up',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-thumbs-up me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span>
								</div>
							</>
						)
					},
					command:  () => {
						navigate.push('/suggestions/approved')
						props.onSidebarClose(true)
					},
					className: isActive('/suggestions/approved')
				},
				{
					label: 'Dismissed',
					icon: 'pi pi-thumbs-down',
					template: (item, options) => {
						return (
							<>
								<div className={options.className} target={item.target} onClick={options.onClick}>
									<span className={'pi pi-thumbs-down me-2'}></span>
									<span className={options.labelClassName}>{item.label}</span>
								</div>
							</>
						)
					},
					command:  () => {
						navigate.push('/suggestions/dismissed')
						props.onSidebarClose(true)
					},
					className: isActive('/suggestions/dismissed')
				}
			]
		}

	let items = [];


	// If user can access jobs
	if(props.capabilities.edit_shop_orders == true || props.capabilities.read_shop_orders == true) {
		items.push(jobs);

		if(props.capabilities.read_bookings_employees == true || props.capabilities.read_bookings_travellers == true) {
			items.push(forcast);
		}
	
		if(companyItems.length > 0) {
			items.push(companies);
		}

		if(customerItems.length > 0) {
			items.push(customers);
		}
	}

	if(props.capabilities.read_travellers == true) {
		// If user can access travellers
		items.push(travellers);
	}

	if(props.capabilities.read_scraps == true) {
		// If user can access scraps
		items.push(scrapSection);
	}

	if(props.capabilities.read_suggestions == true) {
		// If user can access travellers
		items.push(suggestionsSection);
	}

	if(props.capabilities.read_products == true) {
		items.push(products);
	}

	if(props.capabilities.read_bookings_employees == true || props.capabilities.read_bookings_travellers == true) {
		if((props.capabilities.read_shop_orders  != true && props.capabilities.edit_shop_orders != true)) {
			items.push(forcast);
		}
	}

	if(props.capabilities.read_employees == true) {
		// If user can access employees
		items.push(employees);
	}

	if(props.capabilities.length != 0) {
		// If user can access employees
		items.push(account);
	}

	axios.post(getUrl() + '/wp-json/jwt-auth/v1/token/validate', {}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}}).then(
		res => {
			if(res.data.code !== 'jwt_auth_valid_token') {
				localStorage.removeItem("userCapabilities");
				localStorage.removeItem("userToken");

				setNavigation([])
				props.onLogout(true)
			}
		}
	).catch(err => console.log(err));

	setNavigation(items)
	}, [location.pathname, isLoaded, props.statuses, props.companyMenuItems, props.capabilities, localStorage.getItem('userCapabilities')]);

	let logoUrl = '';

	if(props.capabilities.edit_shop_orders == true) {
		logoUrl = '/jobs/sales-order';
	} else if(props.capabilities.edit_products == true) {
		logoUrl = '/products';
	} else if(props.capabilities.edit_travellers == true) {
		logoUrl = '/travellers';
	}

	return (
		<>
			<div className="flex justify-content-center">
				<ScrollPanel style={{ width: '100%', height: '100vh' }} >
					<a href={logoUrl} className="logo-link"><img src="/logo.webp" alt="Company Logo" width="200" height="100%" style={{padding: '1.6rem 1.25rem' }} /></a>
					{
						props.menuItems && (
							<Menu model={navigation} className="custombar1" />
						)
					}
				</ScrollPanel>
			</div> 
		</>
	)
} 
