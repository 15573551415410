import React from "react";
import Navigation from "./navigation";

export default function Header(props) {
	let sidebarShown = 'not-open';

	if(props.sidebarOpen) {
		sidebarShown = 'open';
	}

	function closeSidebar() {
		props.updateSidebarClosed(true);
	}

	function refreshSidebar() {
		props.updateSidebarRefresh(true);
	}

	function systemLogout() {
		props.updateSystemLogout(true);
	}

	return (
		<div className={"bg-light shadow " + sidebarShown}>
			<div className={"d-flex flex-column flex-shrink-0 sidebar bg-white"}>
				<Navigation menuItems={props.menuItems} user={props.currentLoggedInUser} travellerStatusesCount={props.travellerStatusesCount} statuses={props.statuses} priority={props.priority} capabilities={props.capabilities} customerMenuItems={props.customerMenuItems} companyMenuItems={props.companyMenuItems} onSidebarClose={closeSidebar} onSidebarRefresh={refreshSidebar} onLogout={systemLogout} />
			</div>
		</div>
	);
}