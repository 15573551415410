import React from 'react';
import axios from 'axios';
import { getJobTimeFormat } from '../planner/planner';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Link } from 'react-router-dom';
import { decode } from 'html-entities';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';

import { getUrl, getJobTimeBadge, formatDate } from './../planner/planner';
import { InputText } from 'primereact/inputtext';

class EditProductForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			time: '',
			company: '',
			orders: [],
			travellers: [],
			price: '',
			cost_price: '',
			total_materials_price: '',
			bom: [],
			reverseBom: [],
			processes: [],
			selectedRows: [],
			availableProcesses: [],
			categories: [],
			category: null,
			isLoaded: false,
			isLocked: false,
			shouldUpdate: false,
			rowCount: 0,
			binDialog: false,
			isAllocated: false,
			toast: React.createRef()
		}

		this.updateBom = this.updateBom.bind(this);
		this.onRowEditComplete = this.onRowEditComplete.bind(this);
		this.rowEditValidation = this.rowEditValidation.bind(this);
		this.onRowBomEditComplete = this.onRowBomEditComplete.bind(this);
		this.addRow = this.addRow.bind(this); 
		this.addBomRow = this.addBomRow.bind(this); 
		this.deleteRows = this.deleteRows.bind(this); 
		this.productEditor = this.productEditor.bind(this); 
		this.processEditor = this.processEditor.bind(this);
		this.bomFooter = this.bomFooter.bind(this);
		this.getStatus = this.getStatus.bind(this);
		this.getTraveller = this.getTraveller.bind(this);
		this.getActivity = this.getActivity.bind(this);
	}

	/**
	 * Runs when the component mounts
	 */
	async componentDidMount() {
		this.updateProduct();

		const [getProductDetails] = await Promise.all([
			axios.get(getUrl() + '/wp-json/planner/v1/products/' + this.props.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
		]);

		const [getAvailableProcesses] = await Promise.all([
			axios.get(getUrl() + '/wp-json/planner/v1/products/processes', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		]);

		this.setState({
			id: getProductDetails.data.product.id,
			sku: getProductDetails.data.product.product_sku,
			drawing_url: getProductDetails.data.product.product_drawing_url,
			description: getProductDetails.data.product.product_name,
			category: getProductDetails.data.product.product_cat_name,
			travellers: getProductDetails.data.travellers,
			price: getProductDetails.data.product.sales_price,
			cost_price: getProductDetails.data.product.average_cost_price,
			total_materials_price: getProductDetails.data.product.material_total,
			bom: getProductDetails.data.product.bom,
			availableProcesses: getAvailableProcesses.data,
			reverseBom: getProductDetails.data.product.bom_reverse,
			time: getProductDetails.data.product.target_time,
			category: Number(getProductDetails.data.product.product_cat_id),
			isLoaded: true,
			orders: getProductDetails.data.orders,
			isLocked: getProductDetails.data.product.locked,
			manufacturer: Number(getProductDetails.data.product.manufacturer_id),
			manufacturers: getProductDetails.data.product_manufacturers,
			categories: getProductDetails.data.product_categories,
			isLoaded: true,
			last_synced: getProductDetails.data.product.product_last_synced
		});

		if(getProductDetails.data.activities != null) {
			this.setState({
				rowCount: getProductDetails.data.activities.length,
				processes: getProductDetails.data.activities
			})
		}

		document.title = 'Product ' + getProductDetails.data.product.product_sku + ' - Planner';

		setInterval(() => {
			this.lockProduct(getProductDetails);
		}, 15000);

		if(this.state.isLoaded) {
			//this.lockProduct(getProductDetails);
		}
	}

	/**
	 * Lock the product to the ID that is editing
	 * 
	 * @param {Object} productDetails The data for the current product
	 */
	lockProduct(productDetails) {
		/*if(productDetails.data.locked === false) {
			axios.put(getUrl() + '/wp-json/planner/v1/products/' + this.props.id, {
				"locked" : Math.floor(Date.now() / 1000) + ':' + this.props.currentLoggedInUser.id
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then(res => this.setState({
				isLoaded: true
			})).catch(err => console.log(err))
		}*/
	}

	/**
	 * Checks if the product is being edited by another user
	 * 
	 * @returns {Boolean} Returns true or false depending if the product is currently locked
	 */
	isProductLocked() {
		if(this.state.isLocked === false || this.props.currentLoggedInUser.id === this.state.isLocked.ID) {
			return true;
		} else {
			return false;
		}
	}

	lockDropdown() {
		if(this.props.capabilities.edit_products) {
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Gets the current product details and updates the order state.
	 */
	updateProduct() {
		axios.get(getUrl() + '/wp-json/planner/v1/products/' + this.props.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => {
			this.setState({
				id: res.data.product.id,
				orders: res.data.orders,
				sku: res.data.product.product_sku,
				stock: res.data.product.stock,
				drawing_url: res.data.product.product_drawing_url,
				description: res.data.product.product_name,
				category: res.data.product.product_cat_name,
				travellers: res.data.travellers,
				price: res.data.product.sales_price,
				cost_price: res.data.product.average_cost_price,
				total_materials_price: res.data.product.material_total,
				bom: res.data.product.bom,
				reverseBom: res.data.product.bom_reverse,
				time: res.data.product.target_time,
				category: Number(res.data.product.product_cat_id),
				isLoaded: true,
				isLocked: res.data.product.locked,
				manufacturer: Number(res.data.product.manufacturer_id),
				manufacturers: res.data.product_manufacturers,
				categories: res.data.product_categories,
				isLoaded: true,
				last_synced: res.data.product.product_last_synced
			});

		if(res.data.activities != null) {
			this.setState({
				rowCount: res.data.activities.length,
				processes: res.data.activities
			})
		}
		}).catch(err => console.log(err));
	}

	/**
	 * Checks if the component should update based on if an product has changed or the props for the component isLoaded
	 *
	 * @param {Object} prevProps The previous state of the props
	 */
	componentDidUpdate(prevProps) {
		if(this.props.id !== prevProps.id) {
			this.updateProduct(); 
			this.setState({shouldUpdate: false});
		}
	}

	/**
	 * Handles all of the updating and error checking for the form submission.
	 *
	 * @param {Event} event The object for the form that has been submitted
	 */
	updateBom(process) {
		const { toast } = this.state;

		if(process.length !== 0) {
			axios.put(getUrl() + '/wp-json/planner/v1/products/' + this.props.id, {
				"activities" : process
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then(toast.current.show({ life: 700, severity: 'success', summary: 'Updated', detail: 'Activities Updated' })).catch(err => console.log(err));	
		} else {
			axios.put(getUrl() + '/wp-json/planner/v1/products/' + this.props.id, {
				"activities" : []
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then(toast.current.show({ life: 700, severity: 'success', summary: 'Updated', detail: 'Activities Updated' })).catch(err => console.log(err));	
		}
	}

	/**
	 * Checks if the product is in the battery pack category or not.
	 * 
	 * @returns {Boolean} Return true if the product is a battery pack
	 */
	isBatteryPack() {
		let isPack = false;

		if(this.state.bom != null) {
			if(this.state.bom.length > 0) {
				isPack = true;
			}
		}

		return isPack;
	}

	/**
	 * Adds a new row to the processes table.
	 * 
	 * @param {Object} e The form event
	 */
	addRow(e) {
		e.preventDefault();

		let newProcess = [];

		if(this.state.processes) {
			newProcess = this.state.processes
		}

		newProcess.unshift({
			product_process_term_ids: 'Please Choose a process',
			target_time: null,
			id: Math.floor(Math.random() * 1000000000),
			sign_off: false,
			description: '',
			quantity: 1
		});

		let newRowCount = this.state.rowCount;

		newRowCount = newRowCount + 1;

		this.setState({
			rowCount: newRowCount,
			processes: newProcess
		});
	}

	/**
	 * Adds a new row to the processes table.
	 * 
	 * @param {Object} e The form event
	 */
	addBomRow(e) {
		e.preventDefault();

		let newBom = this.state.bom;

		newBom.push({
			product_code: '',
			quantity: '',
			description: '',
			wip: true,
			id: 999
		});

		let newRowCount = this.state.rowCount + 1;

		this.setState({
			bom: newBom,
			rowCount: newRowCount
		});
	}

	productCode(rowData) {
		if(rowData.material_id !== null) {
			return <>
				<Tooltip target=".material-sku" />
				<Link className="material-sku" to={'/product/' + rowData.material_id} data-pr-tooltip={rowData.material_name} data-pr-position="right">{rowData.material_sku}</Link>
			</>;
		} else {
			return <>
				<Tooltip target=".material-sku" />
				<p className="material-sku" data-pr-tooltip={rowData.material_name} data-pr-position="right">{rowData.material_sku}</p>
			</>;
		}
	}

	addedBy(rowData) {
		if(rowData.wip === true) {
			return 'Planner';
		} else {
			return 'Sage';
		}
	}

	/**
	 * When user is finished editing update the state of the product
	 * 
	 * @param {Object} e The form event
	 */
	onRowEditComplete(e) {
		let _processes = [...this.state.processes];
		let { newData, index } = e;
		let newTime = 0;

		_processes[index] = newData;

		_processes.forEach(data => {
			newTime = newTime + data.target_time
		});

		this.setState({
			processes: _processes,
			time: newTime
		});

		this.updateBom(_processes);
	}

	rowEditValidation(e) {
		const { toast } = this.state;

		if(e.target_time !== null) {
			return true;
		} else {
			toast.current.show({ life: 2500, severity: 'error', summary: 'Cannot Save Row', detail: 'Please input a target time' })

			return false;
		}
	}

	/**
	 * When user is finished editing update the state of the product
	 * 
	 * @param {Object} e The form event
	 */
	onRowBomEditComplete(e) {
		let _bom = [...this.state.bom];
		let { newData, index } = e;

		newData.id = newData.product_code;

		axios.get(getUrl() + '/wp-json/wc/v3/products/' + newData.product_code, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => {
			newData.product_code = res.data.sku;

			_bom[index] = newData;

			this.setState({
				bom: _bom
			});
		})
		.catch(err => console.log(err));
	}

	/**
	 * Output the HTML to enable the user to change the process time
	 * 
	 * @param {Object} options The information for editing the data in the row
	 * @returns HTML output of input number so user can change the seconds for the process
	 */
	secondsEditor(options) {
		let value = '';

		if(options.value != 0) {
			value = options.value
		}

		return (
			<div className="p-inputgroup">
				<InputNumber type="text" useGrouping={false} placeholder={"0"} max={86400} value={value} onChange={(e) => options.editorCallback(e.value)} className="w-100" />
				<span className="p-inputgroup-addon">Seconds</span>
			</div>
		);
	}

	getSeconds(rowData) {
		if(rowData.target_time == null) {
			return getJobTimeBadge(0, null, '00:00:00');
		} else {
			return getJobTimeBadge(rowData.target_time);
		}
	}

	/**
	 * Output the HTML to enable the user to change the process time
	 * 
	 * @param {Object} options The information for editing the data in the row
	 * @returns HTML output of input number so user can change the seconds for the process
	 */
	travellerQuantityEditor(options) {
		return (
			<div className="p-inputgroup">
				<InputNumber type="text" useGrouping={false} placeholder="1" min={1} max={10000} value={options.value} onChange={(e) => options.editorCallback(e.value)} className="w-100" />
			</div>
		);
	}

	/**
	 * Output the HTML to enable the user to change the process time
	 * 
	 * @param {Object} options The information for editing the data in the row
	 * @returns HTML output of input number so user can change the seconds for the process
	 */
	travellerDescriptionEditor(options) {
		return (
			<div className="p-inputgroup">
				<InputText value={options.value} onChange={(e) => options.editorCallback(e.target.value)} className="w-100" />
			</div>
		);
	}

	getProcesses() {
		let data = [];

		this.state.availableProcesses.forEach(process => {
				data.push({
					id: 	process.term_id,
					name: process.name
				}
			);
		});

		return data;
	}

	/**
	 * Output the HTML to enable the user to change the process activity
	 * 
	 * @param {Object} options 
	 * @returns HTML to let the user select a process activity
	 */
	processEditor(options) {
		let value = [];

		if(options.value !== 'Please Choose a process') {
			value = options.value;
		}

		return (
			<>
				<div className="d-flex">
					<MultiSelect
						display="chip"
						value={value}
						className="w-100"
						options={this.getProcesses()}
						optionLabel="name"
						optionValue="id"
						onChange={(e) => {
							options.editorCallback(e.value)
						} }
						placeholder="Select a Process"
					/>
				</div>
			</>
		);
	}

	/**
	 * Loops through all selected rows and removes the processes that need to be deleted
	 */
	deleteRows() {
		let newProcesses = this.state.processes;

		for(var i = 0; i < this.state.selectedRows.length; i++) {
			for(var j = 0; j < this.state.processes.length; j++) {
				if(this.state.processes[j].id === this.state.selectedRows[i].id) {
					newProcesses.splice(j, 1);

					this.setState({
						processes: newProcesses
					});
				}
			}
		}

		let newTime = 0;

		newProcesses.forEach(data => {
			newTime = newTime + data.target_time
		});

		this.setState({
			selectedRows: [],
			time: newTime
		});

		this.updateBom(newProcesses);
	}

	changeManufacturer(event) {
		this.setState({
			manufacturer: event.target.value
		});

		const { toast } = this.state;
		axios.put(getUrl() + '/wp-json/planner/v1/products/' + this.props.id, {
			"manufacturer_id" : Number(event.target.value)
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => this.setState({
			isLoaded: true
		}))
		.catch(err => console.log(err));

		toast.current.show({ severity: 'success', summary: 'Product', detail: 'Product Manufacturer updated.' });
	}

	changeCategory(event) {
		this.setState({
			category: event.target.value
		});

		const { toast } = this.state;
		axios.put(getUrl() + '/wp-json/planner/v1/products/' + this.props.id, {
			"product_cat_id" : Number(event.target.value)
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => this.setState({
			isLoaded: true
		}))
		.catch(err => console.log(err));

		toast.current.show({ severity: 'success', summary: 'Product', detail: 'Product category updated.' });
	}

	getTime(rowData) {
		return <>
			<Tooltip autoHide={false} target=".fudge-ratio" />
			<span className="fudge-ratio" 
				style={{ fontSize: '0.75rem' }}
				data-pr-tooltip={"Fudge Ratio: " + getJobTimeFormat(rowData.total_recorded_time * 1.0833)}
				data-pr-position="right"
				data-pr-at="right+5 center"
				data-pr-my="left center"
			>
				{getJobTimeBadge(rowData.total_recorded_time)}
			</span>
		</>
	}

	getTargetTime(rowData) {
		return getJobTimeBadge(rowData.total_target_time);
	}

	getOrderNumber(rowData) {
		return <Link to={'/job/' + rowData.order_id}>{rowData.order_number}</Link>
	}

	quantityEditor(options) {
		return (
			<div className="p-inputgroup">
				<InputNumber useGrouping={false} type="text" value={options.value} onChange={(e) => options.editorCallback(e.value)} className="w-100" />
			</div>
		);
	}

	productEditor(options) {
		return (
			<Dropdown
				value={options.value}
				options={this.state.materials}
				optionLabel="name"
				optionValue="id"
				className="w-100"
				onChange={(e) => options.editorCallback(e.value)}
				placeholder="Select a Product"
			/>
		);
	}

	/**
	 * Checks if there are any outstanding processes which have not been filled in
	 * 
	 * @returns {boolean} True or false depending if the last item on the process table has not been filled in
	 */
	addRowDisabled() {
		let isDisabled = false;

		if(this.state.processes) {
			this.state.processes.forEach(process => {
				if(process.product_process_term_ids === 'Please Choose a process') {
					isDisabled = true;
				}
			});
		}

		return isDisabled;
	}

	getActivity(rowData) {
		let html;

		if(rowData.product_process_term_ids === 'Please Choose a process') {
			html = <span className="badge bg-danger text-white">{rowData.product_process_term_ids} <i className="pi pi-arrow-right text-white" style={{ color: 'slateblue', fontSize: '0.5rem' }}></i></span>
		} else {
			html = '';

			if(Array.isArray(rowData.product_process_term_ids)) {
				let i = 1;
				rowData.product_process_term_ids.forEach(res => {

					this.getProcesses().forEach(process => {
						if(res == process.id) {
							if(rowData.product_process_term_ids.length - 1 == i) {
								html += process.name + ' & ';
							} else {
								html += process.name + ', ';
							}
						}
					});

					i = i + 1;
				});

				html = html.substring(0, html.length - 2)
			} else {
				html = rowData.product_process_term_ids.name;
			}
		}

		return html;
	}

	/**
	 * Modify the title if the order is being edited or viewed by the user and if it's a Sales Order or Pro-Forma
	 * 
	 * @returns {string} The title of the order
	 */
	getProductTitle() {
		let title;

		if(this.props.capabilities.read_products === true) {
			if(this.state.isLoaded) {
				title = 'Viewing Product: ' + this.state.sku;

				if(this.isProductLocked()) {
					title = 'Editing Product: ' + this.state.sku;
				}
			}
		} else {
			title = 'Access Denied';
		}

		return title;
	}

	getActivityQuantity(rowData) {
		let quantity = rowData.quantity;

		if(!quantity) {
			quantity = 1;
		}

		return quantity;
	}

	getsignOff(rowData) {
		if(rowData.sign_off === true) {
			return 'Yes';
		} else {
			return 'No';
		}
	}

	signOffEditor(options) {
		let checked = options.rowData.sign_off;

		return (
			<div className="p-inputgroup">
				<Checkbox checked={checked} onChange={(e) => {
					if(checked == true) {
						options.editorCallback(false)
					} else {
						options.editorCallback(true)
					}
				}}></Checkbox>
			</div>
		);
	}

	getCostPrice(rowData) {
		if(rowData.average_cost_price) {
			return decode(rowData.average_cost_price);
		} else {
			return 'N/A';
		}
	}

	getProductCategory(rowData) {
		let classColour = 'primary';

		if(rowData.product_cat_name === 'Uncategorised') {
			classColour = 'danger';
		}

		return <div className={"badge bg-" + classColour + " text-white"}>{rowData.product_cat_name}</div>;
	}

	getMaterialCategory(rowData) {
		let classColour = 'primary';

		if(rowData.material_cat_name === 'Uncategorised') {
			classColour = 'danger';
		}

		return <div className={"badge bg-" + classColour + " text-white"}>{rowData.material_cat_name}</div>;
	}

	getNetPrice(rowData) {
		if(rowData.average_net) {
			return decode(rowData.average_net);
		} else {
			return 'N/A';
		}
	}

	bomFooter() {
		if(this.state.bom != []) {
			return <div className="d-flex justify-content-end">Total Materials Price: {decode(this.state.total_materials_price)}</div>
		} else {
			return '';
		}
	}

	getTotal(rowData) {
		return decode(rowData.net);
	}

	getSalesPrice(rowData) {
		return decode(rowData.price);
	}

	getStatus(rowData) {
		// Replace all - with a space so it's readable to the users instead of as a slug
		let text = rowData.status_name.replaceAll('-', ' ');

		// Create empty class string to be populated depending on the status // Todo - Cut down using bg-{status}?
		let classes = "bg-" + rowData.status_name;

		// Split the text up and add a capital letter to the start of each word
		const newText = text.split(" ");

		// Set the first letter of the status to a capital
		for (var i = 0; i < newText.length; i++) {
			newText[i] = newText[i].charAt(0).toUpperCase() + newText[i].slice(1);
		}

		text = newText.join(" ");

		if(text === 'Processing') {
			text = 'In Production';
		}

		if(text === 'Pending') {
			text = 'Inputted';
		}

		// If the status is In Produciton, link the badge to a filter to show all travellers associated to that order
		if(text === 'In Production' && this.props.capabilities.read_traveller) {
			return (<Link to={"/travellers?traveller=" + rowData.id}><span className={"badge " + classes}>{rowData.status_label}</span></Link>)
		} else {
			return (<span className={"badge " + classes}>{rowData.status_label}</span>)
		}
	}

	getTraveller(rowData) {
		return <Link to={'/job/' + rowData.order_id}>{rowData.order_number}</Link>
	}

	getTravellerNumber(rowData) {
		return <Link to={'/traveller/' + rowData.id}>{rowData.traveller_number}</Link>
	}

	getTravellerDate(rowData) {
		return formatDate(rowData.date_confirmed);
	}

	getTravellerStatus(rowData) {
		// Replace all - with a space so it's readable to the users instead of as a slug
		let text = rowData.status_name.replaceAll('-', ' ');

		// Create empty class string to be populated depending on the status // Todo - Cut down using bg-{status}?
		let classes = "bg-" + rowData.status_name;

		// Split the text up and add a capital letter to the start of each word
		const newText = text.split(" ");

		// Set the first letter of the status to a capital
		for (var i = 0; i < newText.length; i++) {
			newText[i] = newText[i].charAt(0).toUpperCase() + newText[i].slice(1);
		}

		text = newText.join(" ");

		return (<span className={"badge " + classes}>{text.substr(text.indexOf(" ") + 1)}</span>)
	}

	getOrderDateCreated(rowData) {
		return formatDate(rowData.date_created);
	}

	getReverseBomSku(rowData) {
		return <>
			<Tooltip autoHide={false} target=".reverse-bom-sku" />
			<Link data-pr-tooltip={rowData.product_name} className="reverse-bom-sku" to={"/product/" + rowData.product_id}>{rowData.product_sku}</Link>
		</>
	}

	getAccountNumber(rowData) {
		return <Link to={"/jobs/sales-order?filter=" + rowData.ac_number}>{rowData.ac_number}</Link>
	}

	getProductCategory(rowData) {
		let classColour = 'primary';

		if(rowData.product_cat_name === 'Uncategorised') {
			classColour = 'danger'
		}

		return <div className={"badge bg-" + classColour}>{rowData.product_cat_name}</div>
	}

	render() {
		const { isLoaded, description, sku, category, stock, price, manufacturer, toast, bom, binDialog, selectedRows, cost_price, reverseBom } = this.state;

		var time = this.state.time;
		var isPack = true;
		var outputStock = 0;

		outputStock = stock;

		return (
			<>
				<ConfirmDialog visible={binDialog} blockScroll={true} onHide={() => this.setState({binDialog: false})} message="Are you sure you want to delete selected activities?" header="Confirmation" icon="pi pi-exclamation-triangle" accept={this.deleteRows} />
				<Toast ref={toast} position="bottom-right" />
				<div className="edit-order-bar position-sticky top-0 p-3">
					<div className="container px-0 d-flex justify-content-between align-items-center">
						<div className="d-flex align-items-center">
							<div>
								<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.onSidebarOpen(true);}}>
									<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
								</Button>
							</div>
							<div>
								{
									isLoaded && (
										<h1 className="mb-0 h3 d-flex">{this.getProductTitle()} <a target="_blank" href={this.state.drawing_url}><i className="pi pi-file-pdf ms-1" style={{ fontSize: '1rem' }}></i></a></h1>
									)
								}
								{
									this.props.capabilities.read_products === true && isLoaded && (<>
										<strong>Description:</strong>&nbsp;{decode(description)} <Tooltip autoHide={false} target=".pi-info-circle-description" /><i className="pi pi-info-circle pi-info-circle-description" style={{ fontSize: '0.9rem' }} data-pr-tooltip={"Last Synced: " + formatDate(this.state.last_synced.description, true, false, 'N/A')} data-pr-position="right" data-pr-at="right+5 center" data-pr-my="left center-2"></i>
									</>)
								}
								{
									!isLoaded && (
										<div>
											<i className="pi pi-spin pi-spinner" style={{ fontSize: '2.8rem' }}></i>
										</div>
									)
								}
							</div>
						</div>
						<div className="d-flex align-items-center">
						{
							isLoaded && this.props.capabilities.read_processes === true && ( 
								<>
									{
										this.isBatteryPack() && (
											<div>
												{
													time > 0 && (
														<div className="d-block me-3"><strong>Total Target Time:</strong> {getJobTimeBadge(time)}</div>
													)
												}
												{
													time === 0 && (
														<span className="badge bg-danger d-inline-flex align-items-center display-6">Missing Total Target Time</span>
													)
												}
											</div>
										)
									}
									<div>
										{
											!this.isProductLocked() && (
												<p className="d-inline mb-0 ms-3"><span className="badge bg-danger d-inline-flex align-items-center display-6"><i className="pi pi-lock me-2"></i> {this.state.isLocked.data.user_login} is currently editing this product</span></p>
											)
										}
									</div>
								</>
							)
						}
						</div>
					</div>
				</div>
				{
					isLoaded && this.props.capabilities.read_products === true && ( // https://stackoverflow.com/questions/70682832/
					<>
						<form className="container px-4 px-xxl-0 mt-5">
							<div className="row">
								<div className="col-12">
									<Fieldset legend="Sales/Purchasing Fields">
										<div className="container">
											<div className="row">
												{
													price && (
														<div className="col-12 col-md-6 col-lg-4 mt-2 mt-lg-0">
															<strong>Sales Price: </strong>{decode(price)} <Tooltip autoHide={false} target=".pi-info-circle-regular-price" /><i className="pi pi-info-circle pi-info-circle-regular-price" style={{ fontSize: '0.9rem' }} data-pr-tooltip={"Last Synced: " + formatDate(this.state.last_synced.regular_price, true, false, 'N/A')} data-pr-position="right" data-pr-at="right+5 center" data-pr-my="left center-2"></i>
														</div>
													)
												}
												{
													cost_price && (
														<div className="col-12 col-md-6 col-lg-4 mt-2 mt-lg-0">
															<strong>Cost Price: </strong>{decode(cost_price)} <Tooltip autoHide={false} target=".pi-info-circle-cost-price" /><i className="pi pi-info-circle pi-info-circle-cost-price" style={{ fontSize: '0.9rem' }} data-pr-tooltip={"Last Synced: " + formatDate(this.state.last_synced.average_cost_price, true, false, 'N/A')} data-pr-position="right" data-pr-at="right+5 center" data-pr-my="left center-2"></i>
														</div>
													)
												}
												{
													<div className="col-12 col-md-6 col-lg-4 mt-2 mt-lg-0">
														<strong>Free Stock: </strong> {outputStock} <Tooltip autoHide={false} target=".pi-info-circle-stock" /><i className="pi pi-info-circle pi-info-circle-stock" style={{ fontSize: '0.9rem' }} data-pr-tooltip={"Last Synced: " + formatDate(this.state.last_synced.stock, true, false, 'N/A')} data-pr-position="right" data-pr-at="right+5 center" data-pr-my="left center-2"></i>
													</div>
												}
											</div>
											<div className="row mt-3">
												<div className="col-12 col-md-6 mt-2 mt-lg-0">
													<strong>Category: </strong>
													<Dropdown
														disabled={this.lockDropdown()}
														options={this.state.categories}
														optionLabel="name"
														optionValue="term_id"
														value={this.state.category} placeholder="Select Category" className="mt-1" onChange={this.changeCategory.bind(this)} />
												</div>
												<div className="form-group col-12 col-md-6">
													<label className="d-block"><strong>Manufacturer:</strong>
														<Dropdown
														disabled={this.lockDropdown()}
														options={this.state.manufacturers}
														optionLabel="name"
														optionValue="term_id"
														value={manufacturer} placeholder="Select Manufacturer" className="mt-1" onChange={this.changeManufacturer.bind(this)} />
													</label>
												</div>
											</div>
										</div>
									</Fieldset>
								</div>
							</div>
						</form> 
						<div className="container bom-table px-0 ">
						{
							bom != null && (
								<div className="row mt-5 mb-5 mx-4 mx-xxl-0">
									<div className="col-12 px-0">
										<Fieldset legend={<><span>Bill of Materials (BOM)</span>&nbsp;<Tooltip autoHide={false} target=".pi-info-circle-stock" /><i className="pi pi-info-circle pi-info-circle-stock" style={{ fontSize: '1rem' }} data-pr-tooltip={"Last Synced: " + formatDate(this.state.last_synced.bom, true, false, 'N/A')} data-pr-position="right" data-pr-at="right+5 center" data-pr-my="left center-2"></i></> }>
										<div className="container"> 
											<div className="row justify-content-center mt-3">
												<div className="col-12">
													<DataTable editMode="row" onRowEditComplete={this.onRowBomEditComplete} value={bom} className="mb-3" footer={this.bomFooter}>
														<Column field="product_code" body={this.productCode} header="Material"></Column>
														<Column field="material_qty" editor={this.quantityEditor} header="Quantity" alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
														<Column field="category" header="Category" body={this.getMaterialCategory} alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
														<Column field="average_cost_price" header="Average Cost Price" body={this.getCostPrice} alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
														<Column field="average_net" header="Average Net" body={this.getNetPrice} alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
													</DataTable>
												</div>
											</div>
										</div>
									</Fieldset>
									</div>
								</div>
							)
						}
						{
							reverseBom != null && (
								<div className="row mt-5 mb-5 mx-4 mx-xxl-0">
									<div className="col-12 px-0">
										<Fieldset legend="Reverse Bill of Materials (BOM)">
										<div className="container">
											<div className="row justify-content-center mt-3">
												<div className="col-12">
													<DataTable editMode="row" value={reverseBom} className="mb-3">
														<Column field="product_sku" body={this.getReverseBomSku} header="Product Code"></Column>
														<Column field="product_cat_name" header="Category" body={this.getProductCategory}></Column>
														<Column field="product_qty" header="Quantity" alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
														<Column field="average_cost_price" header="Cost Price" body={this.getCostPrice} alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
													</DataTable>
												</div>
											</div>
										</div>
									</Fieldset>
									</div>
								</div>
							)
						}
						</div>
						{
							this.state.orders != null && (
								<div className="container bom-table px-0">
									<div className="row mt-4 mb-5 mx-4 mx-xxl-0">
										<div className="col-12 px-0">
											<Fieldset legend="Orders">
												<div className="container">
													<div className="row justify-content-center mt-3">
														<DataTable value={this.state.orders} paginator rows={10}>
															<Column field="order_number" header="Order #" body={this.getOrderNumber}></Column>
															<Column field="date_created" header="Created Date" body={this.getOrderDateCreated}></Column>
															<Column field="company_name" header="Customer"></Column>
															<Column field="ac_number" header="A/C" body={this.getAccountNumber}></Column>
															<Column field="status" header="Status" body={this.getStatus}></Column>
															<Column field="product_qty" header="Quantity" alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
															<Column field="sales_price" header="Sales Price" body={this.getSalesPrice} alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
															<Column field="total" header="Total" body={this.getTotal} alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
														</DataTable>
													</div>
												</div>
											</Fieldset>
										</div>
									</div>
								</div>
							)
						}
						{
							this.state.travellers != null && (
								<div className="container bom-table px-0">
									<div className="row mt-4 mb-5 mx-4 mx-xxl-0">
										<div className="col-12 px-0">
											<Fieldset legend="Travellers">
												<div className="container">
													<div className="row justify-content-center mt-3">
														<DataTable value={this.state.travellers} paginator rows={10}>
															<Column field="traveller_number" header="Traveller #" body={this.getTravellerNumber}></Column>
															<Column field="confirmed_date" header="Confirmed Date" body={this.getTravellerDate}></Column>
															<Column field="status" header="Status" body={this.getTravellerStatus}></Column>
															<Column field="order_number" header="Order #" body={this.getOrderNumber}></Column>
															<Column field="product_qty" header="Quantity" alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
															{
																this.props.capabilities.read_processes && (
																	<Column field="time" header="Total Target Time" body={this.getTargetTime} alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
																)
															}
															{
																this.props.capabilities.read_processes && (
																	<Column field="time" header="Total Recorded Time" body={this.getTime} alignHeader={'right'} bodyStyle={{ textAlign: 'right' }}></Column>
																)
															}
														</DataTable>
													</div>
												</div>
											</Fieldset>
										</div>
									</div>
								</div>
							)
						}
						<div className="container bom-table px-0 ">
						{
							this.isBatteryPack() && this.props.capabilities.read_product_activities && (

								<div className="row mt-4 mb-5 mx-4 mx-xxl-0">
									<div className="col-12 px-0">
										<Fieldset legend="Activities">
											<div className="container" >
												<div className="row justify-content-center">
													<div className="mb-3 d-flex justify-content-end" style={{marginTop: '-1.7rem'}}>
														<div>
															{
																this.isProductLocked() && this.props.capabilities.edit_product_activities && (
																	<Button onClick={this.addRow} icon="pi pi-plus" disabled={this.addRowDisabled()}>&nbsp;Add Activity</Button>
																)
															}
															{
																selectedRows.length > 0 && this.isProductLocked() && this.props.capabilities.edit_product_activities && (
																	<Button onClick={() => this.setState({binDialog: true})} icon="pi pi-eraser" className="p-button-danger ms-3">&nbsp;Delete Activity</Button>
																)
															}
														</div>
													</div>
													<DataTable value={this.state.processes} reorderableRows onRowReorder={(e) => { this.setState({processes: e.value}); this.updateBom(e.value); }} editMode="row" onRowEditComplete={this.onRowEditComplete} rowEditValidator={this.rowEditValidation} selectionMode='checkbox' dataKey="id" selection={selectedRows} onSelectionChange={(e) => { if(this.props.capabilities.edit_travellers) { this.setState({selectedRows: e.value}) }}}>
														{
															this.isProductLocked() && this.props.capabilities.edit_product_activities && (
																<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> 
															)
														}
														<Column field="product_process_term_ids" editor={this.processEditor} header="Process(es)" body={this.getActivity}></Column>
														<Column field="target_time" body={this.getSeconds} editor={this.secondsEditor} header="Target Time" bodyStyle={{ maxWidth: '15rem' }}></Column>
														<Column field="quantity" editor={this.travellerQuantityEditor} body={this.getActivityQuantity} alignHeader={'right'} bodyStyle={{ textAlign: 'right', maxWidth: '10rem' }} header="Quantity"></Column>
														<Column field="description" editor={this.travellerDescriptionEditor} header="Variant"></Column>
														<Column field="sign_off" editor={this.signOffEditor} body={this.getsignOff} bodyStyle={{ minWidth: '5rem' }} header="Sign Off"></Column>
														{
															this.isProductLocked() && this.props.capabilities.edit_product_activities && (
																<Column rowEditor headerStyle={{ width: '5%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'right' }}></Column>
															)
														}
														{
															this.state.processes.length > 1 && this.isProductLocked() && this.props.capabilities.edit_travellers && (
																<Column rowReorder style={{ width: '3rem' }} />
															)
														}
													</DataTable>
												</div>
											</div>
										</Fieldset>
									</div>
								</div>
							)
						}
						</div>
					</>
				)
			}
			</>
    );
  }
}
export default EditProductForm;