import React from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Timer } from './timer';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { Fireworks } from '@fireworks-js/react'
import { decode } from 'html-entities';

import { getUrl, formatDate, getJobTimeFormat, getJobTimeBadge } from '../planner/planner';

const delay = ms => new Promise(res => setTimeout(res, ms));

class EditTravellerForm extends React.Component {
	constructor(props) {

		super(props);

		this.state = {
			traveller: [],
			fireFireworks: 0,
			scrap: [],
			isLoaded: false,
			travellerCompletedOn: '',
			isLocked: false,
			isComplete: false,
			canComplete: false,
			noteDialog: false,
			note: '',
			suggestions: [],
			suggestion: '',
			operators: [],
			reportedBy: [],
			supervisors: [],
			managers: [],
			materials: [],
			notes: [],
			operator: '',
			status: '',
			reason: '',
			material: '',
			shouldUpdate: false,
			scrapDialog: false,
			suggestionDialog: false,
			totalTime: null,
			scrapCategories: [],
			suggestionCategories: [],
			scrapCategory: null,
			suggestionCategory: null,
			toast: React.createRef(),
			fireworksRef: React.createRef()
		};

		this.completeTraveller = this.completeTraveller.bind(this);
		this.incrementTotalTimer = this.incrementTotalTimer.bind(this);
		this.getScrapEditButton = this.getScrapEditButton.bind(this);
	}

	/**
	 * Lock the scrap to the ID that is editing
	 * 
	 * @param {Object} scrapDetails The data for the current scrap
	 */
	lockTraveller(travellerDetails) {
		if(travellerDetails.isLocked === false) {
			axios.put(getUrl() + '/wp-json/planner/v1/travellers/' + this.props.id, {
				"locked" : Math.floor(Date.now() / 1000) + ':' + this.props.currentLoggedInUser.id
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then(res => this.setState({
				isLoaded: true
			})).catch(err => console.log(err))
		}
	}

	/**
	 * Checks if the scrap is being edited by another user
	 * 
	 * @returns {Boolean} Returns true or false depending if the scrap is currently locked
	 */
	isTravellerLocked() {
		if((this.state.isLocked === false || this.props.currentLoggedInUser.id === this.state.isLocked.ID) && (this.state.status !== 'planner-completed' && this.state.status !== 'planner-on-hold' && this.state.status !== 'planner-cancelled' || this.props.capabilities.edit_bookings_travellers)) {
			return false;
		} else {
			return true;
		}
	}

	setStatusInProgress() {
		if(this.state.status !== 'planner-completed') {
			this.setState({
				status: 'planner-in-progress'
			});

			axios.put(getUrl() + '/wp-json/planner/v1/travellers/' + this.props.id, {
				"status" : "planner-in-progress",
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then().catch(err => console.log(err))
		}
	}

	/**
	 * Runs when the component mounts
	 */
	async componentDidMount() {
		const [getTravellerDetails] = await Promise.all([
			axios.get(getUrl() + '/wp-json/planner/v1/travellers/' + this.props.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
		]);

		this.setState({
			traveller: getTravellerDetails.data.traveller,
			notes: getTravellerDetails.data.traveller.notes,
			isLocked: getTravellerDetails.data.traveller.locked,
			scrap: getTravellerDetails.data.scraps,
			completed_time: getTravellerDetails.data.traveller.date_completed,
			cancelled_time: getTravellerDetails.data.traveller.date_cancelled,
			reportedBy: getTravellerDetails.data.traveller_authors,
			materials: getTravellerDetails.data.traveller.product_bom,
			suggestions: getTravellerDetails.data.suggestions,
			operators: [],
			supervisors: [],
			managers: [],
			scrapCategories: getTravellerDetails.data.scrap_categories,
			suggestionCategories: getTravellerDetails.data.suggestion_categories,
			travellerCompletedOn: getTravellerDetails.data.traveller.date_completed,
			status: getTravellerDetails.data.traveller.status_name,
			isLoaded: true,
		});

		if(getTravellerDetails.data.traveller.status_name === "planner-completed" || getTravellerDetails.data.traveller.status_name === "planner-on-hold" || getTravellerDetails.data.traveller.status_name === "planner-cancelled") {
			this.setState({
				isLocked: true,
				isComplete: true
			});
		}

		document.title = 'Traveller #' + getTravellerDetails.data.traveller.traveller_number + ' - Planner';

		setInterval(() => {
			this.lockTraveller(getTravellerDetails.data.traveller);
		}, 15000);

		this.lockTraveller(getTravellerDetails.data.traveller);
	}

	/**
	 * Checks if the component should update based on if an product has changed or the props for the component isLoaded
	 *
	 * @param {Object} prevProps The previous state of the props
	 * @param {Object} prevState The previous state of the state
	 */
	async componentDidUpdate(prevProps) {
		if(this.props.id !== prevProps.id) {
			this.updateTraveller(); 
			this.setState({shouldUpdate: false});

			const [getTravellerDetails] = await Promise.all([
				axios.get(getUrl() + '/wp-json/planner/v1/travellers/' + this.props.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
			]);

			setInterval(() => {
				this.lockTraveller(getTravellerDetails.data.traveller);
			}, 15000);
		}
	}

	getSignOffUsers() {
		let usersSignOff = [];

		this.state.managers.forEach((e) => {
			usersSignOff.push(e);
		})

		this.state.supervisors.forEach((e) => {
			usersSignOff.push(e);
		})

		return usersSignOff
	}

	/**
	 * Gets the current product details and updates the order state.
	 */
	updateTraveller() {
		axios.get(getUrl() + '/wp-json/planner/v1/travellers/' + this.props.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => this.setState({
			traveller: res.data,
			notes: res.data.traveller.notes,
			isLoaded: true,
		}))
		.catch(err => console.log(err));
	}

	/**
	 * Create scrap edit button
	 * 
	 * @param {object} rowData Data for the current row
	 * @returns {html} Return the scrap edit button
	 */
	getScrapEditButton(rowData) {
		if(this.props.capabilities.edit_scraps) {
			return (<div className="d-block text-end"><Link to={"/scrap/" + rowData.id} className="btn btn-primary"><i className="pi pi-pencil me-2" style={{ fontSize: '0.8rem' }}></i>Edit</Link></div>);
		} else {
			return (<div className="d-block text-end"><Link to={"/scrap/" + rowData.id} className="btn btn-primary"><i className="pi pi-lock me-2" style={{ fontSize: '0.8rem' }}></i>View</Link></div>);
		}
	}

	/**
	 * Create scrap based on the users input
	 */
	async saveScrap() {
		const { toast } = this.state;

		if(this.state.material && this.state.reason && this.state.scrapOperator) {
			let scrapQty = this.state.scrapQuantity;

			if(!this.state.scrapQuantity) {
				scrapQty = 1;
			} else {
				scrapQty = this.state.scrapQuantity
			}

			await axios.post(getUrl() + '/wp-json/planner/v1/travellers/scraps', {
				"material_id" : this.state.material,
				"material_qty" : scrapQty,
				"issue" : this.state.reason,
				"traveller_id" : this.state.traveller.id,
				"scrap_cat_id" : this.state.scrapCategory,
				"reported_by_user_id" : this.state.scrapOperator
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err))

			toast.current.show({ severity: 'success', summary: 'Scrap', detail: 'Scrap has been added to the traveller.' });

			const [getScrapDetails] = await Promise.all([
				axios.get(getUrl() + '/wp-json/planner/v1/travellers/' + this.props.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
			]);

			this.updateTravellerNotes();

			this.setState({
				scrap: getScrapDetails.data.scraps,
				scrapDialog: false,
				material: null,
				scrapQuantity: null,
				reason: '',
				scrapOperator: null
			});
		} else {
			toast.current.show({ severity: 'error', summary: 'Scrap', detail: 'Please fill in all fields.' });
		}
	}

	/**
	 * Create the edit button for the suggestion
	 * 
	 * @param {object} rowData Data for the current row
	 * @returns {html} The edit button for the suggestion
	 */
	getSuggestionsEditButton(rowData) {
		return (<div className="d-block text-end"><Link to={"/suggestion/" + rowData.id} className="btn btn-primary"><i className="pi pi-pencil me-2" style={{ fontSize: '0.8rem' }}></i>Edit</Link></div>);
	}

	/**
	 * Gets the status for the suggestion
	 * 
	 * @param {object} rowData Data for the current row
	 * @returns {html} The badge for the current status of the suggestion
	 */
	getSuggestionStatus(rowData) {
		let className = '';
		if(rowData.status_label === 'Resolved') {
			className += 'bg-success';
		} else if(rowData.status_label === 'Dismissed') {
			className += 'bg-danger';
		} else {
			className += 'bg-dark';
		}

		return <span className={className + ' badge text-white'}>{rowData.status_label}</span>
	}

	getCategory(rowData) {
		return <div className="badge bg-primary text-white">{rowData.suggestion_cat_name}</div>
	}

	/**
	 * Get the truncated suggestion
	 * 
	 * @param {object} rowData Data for the current row
	 * @returns {string} The truncated suggestion
	 */
	getSuggestion(rowData) {
		let suffix = '';

		if(rowData.suggestion.length > 50) {
			suffix = ' [...]';
		}

		return rowData.suggestion.substring(0, 50) + suffix;
	}

	/**
	 * Creates a suggestion based on the users input
	 */
	async saveSuggestion() {
		const { suggestion, suggestionOperator, toast, suggestionCategory } = this.state;
		if(suggestion !== '' && suggestionOperator !== null && suggestionCategory !== null) {
			await axios.post(getUrl() + '/wp-json/planner/v1/travellers/suggestions', {
				"traveller_id" : this.props.id,
				"suggestion" : suggestion,
				"reported_by_user_id" : suggestionOperator,
				"suggestion_cat_id" : suggestionCategory,
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err))

			this.updateTravellerNotes();

			toast.current.show({ severity: 'success', summary: 'Suggestion', detail: 'Suggestion has been added to the traveller.' });

			const [getSuggestionDetails] = await Promise.all([
				axios.get(getUrl() + '/wp-json/planner/v1/travellers/' + this.props.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			]);

			this.setState({
				suggestions: getSuggestionDetails.data.suggestions,
				suggestionDialog: false,
				suggestion: '',
				suggestionOperator: null
			});
		} else {
			toast.current.show({ severity: 'error', summary: 'Suggestion', detail: 'Please fill in all fields.' });
		}
	}

	/**
	 * Get the date / time for the current row in the correct format
	 * 
	 * @param {object} rowData Data for the current row
	 * @returns Gets the date / time for the current row in the correct format
	 */
	getDate(rowData) {
		return formatDate(rowData.date_reported, true);
	}

	getScrapMaterial(rowData) {
		return <><Tooltip autoHide={false} target=".material-description" /> <Link data-pr-tooltip={rowData.material_description} className="material-description" to={"/product/" + rowData.material_id} >{rowData.material_sku}</Link> <span className="badge bg-primary text-white">x {rowData.material_qty}</span></>;
	}

	getFault(rowData) {
		return <div className="badge bg-primary text-white">{rowData.scrap_cat_name}</div>
	}

	getDateReplaced(rowData) {
		return formatDate(rowData.date_replaced, true);
	}

	/**
	 * Get the date / time for the current row in the correct format
	 * 
	 * @param {object} rowData Data for the current row
	 * @returns Gets the date / time for the current row in the correct format
	 */
	getCommentDate(rowData) {
		return formatDate(rowData.comment_date, true);
	}

	getNoteContent(rowData) {
		return decode(rowData.comment_content);
		
	}

	getCommentId(rowData, props) {
		return this.state.notes.length - props.rowIndex;
	}

	updateNotes() {
		this.updateTravellerNotes();
	}

	updateSync() {
		axios.get(getUrl() + '/wp-json/planner/v1/travellers/' + this.props.traveller.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => {
			this.setState({
				traveller: res.data
			});
		})
		.catch(err => console.log(err));
	}

	async completeTraveller() {
		let canComplete = true;
		let timersRunning = false;
		let SignOffMissing = false;

		if(this.state.traveller.traveller_activities) {
			this.state.traveller.traveller_activities.forEach(res => {
				if(res.running !== 'false') {
					canComplete = false;
					timersRunning = true;
				}

				if(res.process.sign_off == true && res.sign_off_data.length == 0) {
					canComplete = false;
					SignOffMissing = true;
				}
			});
		}

		if(canComplete) {
			axios.put(getUrl() + '/wp-json/planner/v1/travellers/' + this.props.id, {
				"status" : "planner-completed",
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then().catch(err => console.log(err))

			this.setState({
				isComplete: true
			});

			this.updateTravellerNotes();

			this.setState({
				isLocked: true,
				status: 'planner-completed',
				completed_time: new Date(),
				fireFireworks: 60
			});

			this.state.toast.current.show({ severity: 'success', summary: 'Traveller Completed', detail: 'This traveller has been completed.' });

			await delay(5000);

			this.setState({
				fireFireworks: 0
			})
		} else {
			if(timersRunning) {
				this.state.toast.current.show({ severity: 'error', summary: 'Cannot Complete', detail: 'This traveller has running timers.' });
			} else if(SignOffMissing) {
				this.state.toast.current.show({ severity: 'error', summary: 'Cannot Complete', detail: 'Activity sign off missing.' });
			} else {
				this.state.toast.current.show({ severity: 'error', summary: 'Cannot Complete', detail: 'Traveller cannot be completed.' });
			}
		}
	}

	getMaxQuantity() {
		let maxQuantity = 1;

		if(this.state.traveller.product_bom) {
			this.state.traveller.product_bom.map(product => {
				if(Number(product.material_id) == this.state.material) {
					maxQuantity = Number(product.material_qty);
				}
			})
		}

		return maxQuantity;
	}

	capitalEachLetter(words) {
		var separateWord = words.toLowerCase().split(' ');
		for (var i = 0; i < separateWord.length; i++) {
			 separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
			 separateWord[i].substring(1);
		}

		return separateWord.join(' ');
 }
 
	statusText(text) {
		return this.capitalEachLetter(text.replace('planner-','').replace('-', ' '));
	}

	async changeStatus(event) {
		axios.put(getUrl() + '/wp-json/planner/v1/travellers/' + this.props.id, {
			"status" : event.target.value,
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then().catch(err => console.log(err))

		this.updateTravellerNotes();

		this.state.toast.current.show({ severity: 'success', summary: 'Status Changed', detail: 'The status has been changed.' });

		this.setState({
			status: event.target.value
		});

		if(event.target.value == 'planner-completed' || event.target.value == 'planner-on-hold' || event.target.value == 'planner-cancelled') {
			this.setState({
				isLocked: true,
				isComplete: true
			});
		} else {
			this.setState({
				isLocked: false,
				isComplete: false
			});
		}
	}

	getPageTitle() {
		if(this.props.capabilities.length != 0) {
			if(this.props.capabilities.edit_travellers === true || this.props.capabilities.read_travellers === true) {
				if(this.state.isComplete || this.props.capabilities.edit_travellers != true) {
					return 'Viewing Traveller: ';
				} else {
					return 'Editing Traveller: ';
				}
			} else {
				return 'Access Denied';
			}	
		}
	}

	incrementTotalTimer(data) {
		this.setState({
			totalTime: data
		});
	}

	/**
	 * Update the state of the order notes
	 */
	updateTravellerNotes() {
		axios.get(getUrl() + '/wp-json/planner/v1/travellers/' + this.props.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => this.setState({
			notes: res.data.traveller.notes,
		}))
		.catch(err => console.log(err));
	}

	getOperatorsAndSupervisors() {
		let users = this.state.operators.concat(this.state.supervisors);

		users.sort((a, b) => a.name.localeCompare(b.name))

		return this.state.reportedBy;
	}

	/**
	 * Create a note for the suggestion
	 */
	async addNote() {
		if(this.state.note) {
			this.setState({
				noteDialog: false
			});

			const { toast } = this.state;

			await axios.put(getUrl() + '/wp-json/planner/v1/travellers/' + this.props.id, {
				"note" : this.state.note,
				"note_user_id" : this.state.operator
			}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err));

			toast.current.show({ severity: 'success', summary: 'Traveller', detail: 'Traveller note added.' });

			this.setState({
				note: ''
			});

			this.updateTravellerNotes();
		} else {
			this.state.toast.current.show({ severity: 'error', summary: 'Note', detail: 'Please enter text in the note field.' });
		}
	}

	getStatusLabel(rowData) {
		return <span className={"badge bg-dark"}>{rowData.status_label}</span>
	}

	getAuthorLink(rowData) {
		if(rowData.user_id != 0 && this.props.capabilities.edit_employees) {
			return <Link to={'/employee/' + rowData.reported_by_user_id}>{rowData.reported_by_display_name}</Link>
		} else {
			return rowData.reported_by_display_name;
		}
	}

	render() {
		const { isLoaded, traveller, toast, suggestionDialog, scrapDialog, suggestion, reason, notes, noteDialog, note, fireworksRef } = this.state;

		let badgeClass = 'bg-success';

		if(this.state.totalTime > this.state.traveller.total_target_time) {
			if(this.state.totalTime < this.state.traveller.total_target_time * 1.1) {
				badgeClass = 'bg-warning';
			} else {
				badgeClass = 'bg-danger';
			}
		}

		let activitiesStyling = {}

		if(this.props.capabilities.edit_scraps) {
			activitiesStyling = {marginTop: '-1.7rem'}
		}

		return (
			<>
				{
					this.state.fireFireworks != 0 && (
						<Fireworks
							ref={fireworksRef}
							options={{ opacity: 0.5, intensity: this.state.fireFireworks, explosion: 10, particles: 200 }}
							style={{
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							position: 'fixed',
							zIndex: 999999999999
							}}
						/>
					)
				}
				<Dialog blockScroll={true} header="Add Note" visible={noteDialog} style={{ width: '50vw' }} onHide={(e) => this.setState({noteDialog: false})}>
					<Dropdown placeholder="Select an Operator"  className="w-100 mb-4" filterInputAutoFocus={false} filter value={this.state.operator} options={this.getOperatorsAndSupervisors()} onChange={(e) => this.setState({operator: e.target.value})} optionLabel="display_name" optionValue="id" />
					<InputTextarea value={note} onChange={(e) => this.setState({note: e.target.value})} rows={5} maxLength={500} placeholder="Add Note" />
					<span>{(500 - note.length)+ " characters left"}</span><br />
					<Button type="button" label="Save Note" icon="pi pi-send" onClick={this.addNote.bind(this)} className="mt-4" />
				</Dialog>
				<Dialog blockScroll={true} header="Add Suggestion" style={{ width: '60vw' }} visible={suggestionDialog} onHide={() => this.setState({suggestionDialog: false})}>
					<div className="container px-0">
						<div className="row mb-3">
						<div className="col-12 col-md-6">
								<strong className="d-block mb-1">Reported By:</strong>
								<Dropdown placeholder="Select a user" className="w-100" filterInputAutoFocus={false} value={this.state.suggestionOperator} options={this.state.reportedBy} onChange={(e) => this.setState({suggestionOperator: e.target.value})} optionLabel="display_name" optionValue="id" />
							</div>
							<div className="col-12 col-md-6">
								<strong className="d-block mb-1">Category:</strong>
								<Dropdown placeholder="Select a type" className="w-100" filterInputAutoFocus={false} value={this.state.suggestionCategory} options={this.state.suggestionCategories} onChange={(e) => this.setState({suggestionCategory: e.target.value})} optionLabel="name" optionValue="id" />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<strong className="d-block mb-1">Suggestion:</strong>
							<InputTextarea rows={5} value={suggestion} maxLength={500} placeholder="Enter Suggestion" onChange={(e) => this.setState({suggestion: e.target.value})} />
							<span>{(500 - suggestion.length)+ " characters left"}</span>
						</div>
					</div>
					<Button type="button" label="Save" icon="pi pi-save" className="mt-4" onClick={this.saveSuggestion.bind(this)} />
				</Dialog>
				{
					isLoaded && (
						<Dialog blockScroll={true} header="Add Scrap" style={{ width: '60vw' }} visible={scrapDialog} onHide={() => this.setState({scrapDialog: false})}>
						<div className="container px-0">
							<div className="row mb-3">
								<div className="col-12 col-md-6 col-xxl-3">
									<strong className="d-block mb-1">Reported By:</strong>
									<Dropdown className="w-100" placeholder="Select an Operator" value={this.state.scrapOperator} options={this.state.reportedBy} onChange={(e) => this.setState({scrapOperator: e.target.value})} optionLabel="display_name" optionValue="id" />
								</div>
								<div className="col-12 col-md-6 col-xxl-3">
									<strong className="d-block mb-1">Material:</strong>
									<Dropdown className="w-100" placeholder="Select a Material" value={this.state.material} options={(this.state.materials)} optionLabel="material_sku" optionValue="material_id" onChange={(e) => this.setState({material: e.target.value})} />
								</div>
								<div className="col-12 col-md-6 mt-2 mt-xxl-0 col-xxl-3">
									<strong className="d-block mb-1">Fault:</strong>
									<Dropdown className="w-100" placeholder="Select a Fault" value={this.state.scrapCategory} options={(this.state.scrapCategories)} optionLabel="name" optionValue="id" onChange={(e) => this.setState({scrapCategory: e.target.value})} />
								</div>
								<div className="col-12 col-md-6 mt-2 mt-xxl-0 col-xxl-3">
									<strong className="d-block mb-1">Quantity:</strong>
									<InputNumber className="w-100" min={1} value={this.state.scrapQuantity} onChange={(e) => {
											this.setState({scrapQuantity: e.value})
									}} placeholder="Enter Quantity" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<strong className="d-block mb-1">Issue:</strong>
								<InputTextarea rows={5} value={reason} maxlength={500} onChange={(e) => this.setState({reason: e.target.value})} placeholder="Enter Scrap Issue" />
								<span>{(500 - reason.length) + " characters left"}</span>
							</div>
						</div>
						<Button type="button" label="Save" icon="pi pi-save" className="mt-4" onClick={this.saveScrap.bind(this)} />
					</Dialog>
					)
				}
				<Toast ref={toast} position="bottom-right" />
				<div className="edit-order-bar position-sticky top-0 p-3"> 
					<div className="container px-0 d-xl-flex justify-content-between align-items-center">
						<div>
							<div className="d-flex">
								<div className="d-flex align-items-center">
									<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.onSidebarOpen(true);}}>
										<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
									</Button>
								</div>
								<div>
								{
									isLoaded && (
										<h1 className="mb-0 h3">{this.getPageTitle()}
										{
											<span>#{traveller.traveller_number}</span>
										}
									</h1>
									)
								}
								{
									isLoaded && this.props.capabilities.read_products === true && (<>
										<strong>Product:</strong><Tooltip autoHide={false} target=".product-description" /> <Link data-pr-tooltip={traveller.product_name} data-pr-position="left" className="product-description" to={'/product/' + traveller.product_id}>{traveller.product_sku}</Link> <span className="badge bg-primary text-white">x {traveller.product_qty}</span> 
										<a target="_blank" href={traveller.product_drawing_url}><i className="pi pi-file-pdf ms-1" style={{ fontSize: '0.8rem' }}></i></a>
									</>)
								}
								{
									!isLoaded && (
										<div>
											<i className="pi pi-spin pi-spinner" style={{ fontSize: '2.8rem' }}></i>
										</div>
									)
								}
								</div>
							</div>
						</div>
						<div className="d-flex align-items-center traveller-items mt-2 mt-xl-0">
						{
							isLoaded && this.state.totalTime !== null && (
								<div className="d-flex flex-column">
								<div className="target-time mb-1 d-flex justify-content-end"><strong>Total Target Time:&nbsp;</strong>
								{
									this.state.traveller.total_target_time != 0 && (
										getJobTimeBadge(this.state.traveller.total_target_time, false, 'Missing Time')
									)
								}
								{
									this.state.traveller.total_target_time == 0 && (
										getJobTimeBadge(this.state.traveller.total_target_time, false, 'Missing Time', 'bg-danger')
									)
								}
								</div>
								<div className="recorded-time d-flex justify-content-end">
									<strong>Total Recorded Time: </strong>
									<div className="ms-2">
										<Tooltip autoHide={false} target=".fudge-ratio" />
										<span className={"badge text-white fudge-ratio " + badgeClass} data-pr-tooltip={"Fudge Ratio: " + getJobTimeFormat(this.state.totalTime * 1.0833)}
										data-pr-position="right"
										data-pr-at="right+5 center"
										data-pr-my="left center">
											<i className="pi pi-stopwatch me-1" style={{ fontSize: '0.75rem' }}></i>{getJobTimeFormat(this.state.totalTime)}
										</span>
									</div>
								</div>
							</div>
							)
						}
						{
							isLoaded && this.props.capabilities.edit_travellers === true && (
								<>
									{
										!this.isTravellerLocked() && this.state.status !== 'planner-completed' && this.state.status !== 'planner-on-hold' && this.state.status !== 'planner-cancelled' && (<>
											<Button type="button" severity="success" label="Complete" icon="pi pi-check" onClick={(e) => this.completeTraveller()} className="ms-xl-3" />
										</>)
									}
									{
										this.isTravellerLocked() && this.state.status !== 'planner-completed' && this.state.status !== 'planner-on-hold' && this.state.status !== 'planner-cancelled' && (<>
											{
												this.state.isLocked !== true && this.state.isLocked.data.user_login && (
													<p className="d-inline mb-0 ms-3"><span className="badge bg-danger d-inline-flex align-items-center display-6"><i className="pi pi-lock me-2"></i> {this.state.isLocked.data.user_login} is currently editing this traveller</span></p>
												)
											}
										</>)
									}
									{
										this.state.status === 'planner-completed' && (
											<p className="d-inline mb-0 ms-3"><span className="badge bg-success d-inline-flex align-items-center display-6"><i className="pi pi-calendar me-2"></i>Completed on {formatDate(this.state.completed_time)}</span></p>
										)
									}
									{
										this.state.status === 'planner-on-hold' && (
											<p className="d-inline mb-0 ms-3"><span className="badge bg-on-hold d-inline-flex align-items-center display-6"><i className="pi pi-ban me-2"></i>On Hold</span></p>
										)
									}
									{
										this.state.status === 'planner-cancelled' && (
											<p className="d-inline mb-0 ms-3"><span className="badge bg-danger d-inline-flex align-items-center display-6"><i className="pi pi-times me-2"></i>Cancelled on {formatDate(this.state.cancelled_time)}</span></p>
										)
									}
								</>
							)
						}
						</div>
					</div>
				</div>
				{
					isLoaded && ( // https://stackoverflow.com/questions/70682832/
					<>
						<div className="traveller container px-3 px-xxl-0 my-5">
							<div className="row">
								<div className="col-12">
									<Fieldset legend="Sales Fields">
										<div className="container-fluid">
											<div className="row">
												<div className="col-12 col-md-6 col-xl-4 mt-2 mt-xl-0">
													{
														this.props.capabilities.read_shop_orders && (<>
															<strong>Order #: </strong> <Link to={'/job/' + traveller.order_id}>{traveller.order_number}</Link> 
														</>)
													}
													{
														!this.props.capabilities.read_shop_orders && (<>
															<strong>Order #: </strong> {traveller.order_number}
														</>)
													}
												</div>
												<div className="col-12 col-md-6 col-xl-4 mt-2 mt-xl-0">
													<strong>Line Item #: </strong>
													{
														traveller.line_item_number != null && (
															traveller.line_item_number
														)
													}
													{
														traveller.line_item_number == null && (
															'N/A'
														)
													}
												</div>
												<div className="col-12 col-md-6 col-xl-4 mt-2 mt-xl-0">
													<strong>A/C: </strong><Link to={'/travellers/prepared?filter=' + traveller.order_ac_number}>{traveller.order_ac_number}</Link>
												</div>
												<div className="col-12 col-md-6 col-xl-4 mt-2">
													<strong>Customer: </strong>{traveller.order_company}
												</div>
												<div className="col-12 col-md-6 col-xl-4 mt-2">
													<strong>Customer Order #: </strong>{traveller.cust_order_number}
												</div>
												<div className="col-12 col-md-6 col-xl-4 mt-2">
													<strong>Account Manager: </strong>{traveller.account_manager_to_display}
												</div>
											</div>
										</div>
									</Fieldset>
								</div>
								<div className="col-12 mt-4">
									<Fieldset legend="Production Fields">
										<div className="container-fluid">
											<div className="row">
												<div className="col-12 col-md-6 col-xl-4 mt-2 mt-xl-0">
													<strong>Created Date: </strong>{formatDate(traveller.date_created, true)}
												</div>
												<div className="col-12 col-md-6 col-xl-4">
													<strong>Confirmed Date: </strong>{formatDate(traveller.date_confirmed)}
												</div>
												<div className="col-xl-4"></div>
												{
														<div className="col-4 mt-3">
															<strong className="d-block w-100">Traveller Status: </strong>
															<Dropdown className="w-100 mt-1"
																options={[
																	{ name: 'Inputted', slug: 'planner-inputted' },
																	{ name: 'Prepared', slug: 'planner-prepared' },
																	{ name: 'In Progress', slug: 'planner-in-progress' },
																	{ name: 'Completed', slug: 'planner-completed' },
																	{ name: 'On Hold', slug: 'planner-on-hold' },
																	{ name: 'Cancelled', slug: 'planner-cancelled' }
																]}
																value={this.state.status}
																optionLabel="name"
																placeholder="Traveller Status"
																optionValue="slug"
																disabled={this.isTravellerLocked()}
																onChange={this.changeStatus.bind(this)}
															></Dropdown>
														</div>
												}
											</div>
										</div>
									</Fieldset>
								</div>
							</div>
							<div className="row mt-4">
								<div className="col-12">
									<Fieldset legend="Activities">
										<div className="container-fluid">
											<div className="row">
												<div className="col-12" style={activitiesStyling}>
													<Timer jobTargetTime={getJobTimeFormat(traveller.total_target_time)} currentTotalTimer={data => {this.incrementTotalTimer(data)}} setStatusInProgress={this.setStatusInProgress.bind(this)} updateNotes={this.updateNotes.bind(this)} updateSync={this.updateSync.bind(this)} currentLoggedInUser={this.props.currentLoggedInUser} capabilities={this.props.capabilities} isLocked={this.isTravellerLocked()} traveller={traveller} operators={this.getOperatorsAndSupervisors()} supervisors={this.getSignOffUsers()} travellerId={traveller.id} quantity={traveller.quantity} status={this.state.status} />
												</div>
											</div>
										</div>
									</Fieldset>
								</div>
							</div>
							{
									<div className="row mt-4">
										<div className="col-12">
											<Fieldset legend="Scraps">
												<div className="container-fluid" style={{position: 'relative'}}>
													{
														isLoaded && this.props.capabilities.edit_travellers === true && (
															<>
																{
																	!this.isTravellerLocked() && (<>
																		<div className="col-12 d-flex align-items-end justify-content-end" style={{height: '25px', marginBottom: '1rem'}}>
																			<div>
																				<Button outlined type="button" label="Scrap" icon="pi pi-plus" onClick={(e) => this.setState({scrapDialog: true})} />
																			</div>
																		</div>
																	</>)
																}
															</>
														)
													}
													<div className="row">
														<div className="col-12">
															<DataTable value={this.state.scrap} dataKey="id">
																<Column field="scrap_number" header="Scrap #"></Column>
																<Column field="date" header="Reported Date" body={this.getDate}></Column>
																<Column field="reported_by_display_name" header="Reported By" body={this.getAuthorLink.bind(this)}></Column>
																<Column field="status_label" body={this.getStatusLabel} header="Status"></Column>
																<Column field="material_sku" header="Material" body={this.getScrapMaterial}></Column>
																<Column field="fault" header="Fault" body={this.getFault}></Column>
																<Column field="date_replaced" header="Date Replaced" body={this.getDateReplaced}></Column>
																<Column body={this.getScrapEditButton} header=""></Column>
															</DataTable>
														</div>
													</div>
												</div>
											</Fieldset>
										</div>
									</div>
							}
							{
									<div className="row my-4">
										<div className="col-12">
											<Fieldset legend="Suggestions">
												<div className="container-fluid" style={{position: 'relative'}}>
													{
														isLoaded && this.props.capabilities.edit_travellers === true && (
															<>
																{
																	!this.isTravellerLocked() && (<>
																		<div className="col-12 d-flex align-items-end justify-content-end" style={{height: '25px', marginBottom: '1rem'}}>
																			<div>
																				<Button outlined type="button" label="Suggestion" icon="pi pi-plus" onClick={(e) => this.setState({suggestionDialog: true})} className="ms-3 me-3 me-xl-0" />	
																			</div>
																		</div>
																	</>)
																}
															</>
														)
													}
													<div className="row">
														<div className="col-12">
															<DataTable value={this.state.suggestions} dataKey="id">
																<Column field="suggestion_number" header="Suggestion #"></Column>
																<Column field="date" header="Reported Date" body={this.getDate}></Column>
																<Column field="reported_by_display_name" header="Reported By" body={this.getAuthorLink.bind(this)}></Column>
																<Column field="status" body={this.getSuggestionStatus} header="Status"></Column>
																<Column field="catgory" body={this.getCategory} header="Category"></Column>
																<Column field="suggestion" header="Suggestion" body={this.getSuggestion}></Column>
																<Column body={this.getSuggestionsEditButton} header=""></Column>
															</DataTable>
														</div>
													</div>
												</div>
											</Fieldset>
										</div>
									</div>
							}
							{
								<>
									<div className="row mt-4 mb-5">
										<div className="col-12">
											<Fieldset legend="Notes">
												<div className="container">
													<div className="row mt-0">
														<div style={activitiesStyling}>
															{
																this.props.capabilities.edit_travellers && (
																	<div className="d-flex justify-content-end mb-3">
																		<Button type="button" severity="primary" label="Add Note" icon="pi pi-comments" onClick={(e) => this.setState({noteDialog: true})} className="ms-xl-3" />
																	</div>
																)
															}
															<DataTable value={notes} tableStyle={{ minWidth: '50rem' }} paginator rows={10} sortOrder={-1} sortField="id" className="notes_table">
																<Column header="Note #" field="comment_ID" body={this.getCommentId.bind(this)} headerStyle={{ width: '7rem' }}></Column>
																<Column field="comment_date" header="Date" body={this.getCommentDate} headerStyle={{ width: '12rem' }}></Column>
																<Column field="comment_content" header="Note" body={this.getNoteContent}></Column>
																<Column field="comment_author" header="Author" headerStyle={{ width: '15rem' }}></Column>
															</DataTable>
														</div>
													</div>
												</div>
											</Fieldset>
										</div>
									</div>
								</>
							}
						</div> 
					</>
				)
			}
			</>
    );
  }
}
export default EditTravellerForm;