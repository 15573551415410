import React from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { EmployeeHours } from './employee-hours';
import { HourBookings } from './hour-bookings';

import { getUrl, formatDate, getJobTimeBadge } from './../planner/planner';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

class EditScrapForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			traveller: null,
			scrap: null,
			suggestions: null,
			employeeBasicHours: null,
			expandedRows: []
		}
	}

	/**
	 * Runs when the component mounts
	 */
	async componentDidMount() {
		const [getEmployeeReport] = await Promise.all([
			axios.get(getUrl() + '/wp-json/planner/v1/employees/' + this.props.id, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }),
		]);

		if(this.state.isLoaded === false) {
			this.setState({
				traveller: getEmployeeReport.data.travellers,
				scrap: getEmployeeReport.data.scraps,
				suggestions: getEmployeeReport.data.suggestions,
				name: getEmployeeReport.data.employee.name,
				number: getEmployeeReport.data.employee.employee_number,
				basicHours: getEmployeeReport.data.basic_hours,
				operator: getEmployeeReport.data.employee,
				isLoaded: true,
			});

			document.title = getEmployeeReport.data.employee.name + ' - Planner';
		}
	}

	/**
	 * Get the formatted start date for the employee process
	 * 
	 * @param {object} rowData The data for the row
	 * @returns The formatted start date
	 */
	getDateStart(rowData) {
		return formatDate(rowData.date_start, true);
	}

	/**
	 * Get the formatted end date for the employee process
	 * 
	 * @param {object} rowData The data for the row
	 * @returns The formatted end date
	 */
	getDateEnd(rowData) {
		return formatDate(rowData.date_end, true);
	}

	/**
	 * Get the time spent on the process
	 * 
	 * @param {object} rowData The data for the row
	 * @returns The time spent on the process
	 */
	getTimeSpent(rowData) {
		return getJobTimeBadge(rowData.total_recorded_time);
	}

	/**
	 * Get the traveller number for the process and make it clickable for the user
	 * 
	 * @param {object} rowData The data for the row
	 * @returns Get the traveller number for the process
	 */
	getTravellerNumber(rowData) {
		const url = "/traveller/" + rowData.traveller_id;

		return <Link to={url}>{rowData.traveller_number}</Link>
	}

	/**
	 * Get the order number and make it clickable to view the order
	 * 
	 * @param {object} rowData The data for the row
	 * @returns A link to the order number for the process
	 */
	getOrderNumber(rowData) {
		const url = "/job/" + rowData.order_id;

		return <Link to={url}>{rowData.order_number}</Link>
	}

	/**
	 * Get the product sku for the process
	 * 
	 * @param {object} rowData The data for the row
	 * @returns A link to the product associated for the process
	 */
	getProductSku(rowData) {
		const url = "/product/" + rowData.product_id;

		return <>
			<Link to={url}>{rowData.product_sku}</Link>
			<span className="badge bg-primary text-white ms-1">x {rowData.product_qty}</span>
		</>
	}

	getTravellerNumber(rowData) {
		const url = "/traveller/" + rowData.id;

		return <Link to={url}>{rowData.traveller_number}</Link>
	}

	getTravellerStatus(rowData) {
		// Replace all - with a space so it's readable to the users instead of as a slug
		let text = rowData.status_name.replaceAll('-', ' ');

		// Create empty class string to be populated depending on the status // Todo - Cut down using bg-{status}?
		let classes = "bg-" + rowData.status_name;

		// Split the text up and add a capital letter to the start of each word
		const newText = text.split(" ");

		// Set the first letter of the status to a capital
		for (var i = 0; i < newText.length; i++) {
			newText[i] = newText[i].charAt(0).toUpperCase() + newText[i].slice(1);
		}

		text = newText.join(" ");

		return (<span className={"badge " + classes}>{text.substr(text.indexOf(" ") + 1)}</span>)
	}

	getTotalTargetTime(rowData) {
		return getJobTimeBadge(rowData.total_target_time);
	}

	/**
	 * Get the cost price header with the tooltip
	 * 
	 * @returns {html} The header for the cost price with the tooltip
	 */
	getTotalRecordedTimeHeader() {
		return <><Tooltip autoHide={false} target=".pi-question-circle" /><span>Total Recorded Time <i className="pi pi-question-circle" style={{ fontSize: '0.75rem' }} 
			data-pr-tooltip="Entire traveller recorded time not specific to user viewed"
			data-pr-position="left"
			data-pr-at="right+5 top" 
			data-pr-my="left center-2"
		></i></span></>;
	}
	getSignedOff(rowData) {
		if(rowData.supervisor_name != null) {
			return <><span>{rowData.supervisor_name}</span><span className="d-block" style={{fontSize: '0.7rem'}}>{formatDate(rowData.date_sign_off, true)}</span></>;
		} else {
			return 'N/A';
		}
	}

	getTotalRecordedTime(rowData) {
		return getJobTimeBadge(rowData.activity_recorded_time);
	}

	getAssembled(rowData) {
		if(rowData.activity_assembled) {
			return rowData.activity_assembled
		}
	}

	/**
	 * Generate the template for the functionality when a user clicks to expand a row.
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns The template for the row epansion which includes all of the notes for the order
	 */
	rowExpansionTemplate(rowData) {
		console.log(rowData.activities[0]);
		return (
			<DataTable value={rowData.activities[0]} className="mx-5 my-2">
				<Column field="activity_number" header="Activity #"></Column>
				<Column field="activity_processes_name" header="Process Name"></Column>
				<Column field="activity_recorded_time" header="Recorded Time" body={this.getTotalRecordedTime}></Column>
				<Column field="activity_assembled" header="Assembled" body={this.getAssembled}></Column>
				<Column field="activity_expected" header="Expected"></Column>
				<Column field="sign_off_data" header="Signed Off" body={this.getSignedOff}></Column>
			</DataTable>
		);
	}

	getScrapNumber(rowData) {
		const url = "/scrap/" + rowData.id;

		return <Link to={url}>{rowData.scrap_number}</Link>
	}

	/**
	 * Get the badge for the status
	 * 
	 * @param {Object} rowData Information for the current row
	 * @returns {html} Get the status with the correct colouring in a badge
	 */
	getStatus(rowData) {
		let className = '';
		if(rowData.status_label === 'Resolved') {
			className += 'bg-success';
		} else if(rowData.status_label === 'Dismissed') {
			className += 'bg-danger';
		} else {
			className += 'bg-dark';
		}

		return <span className={className + ' badge text-white'}>{rowData.status_label}</span>
	}

	getSuggestionNumber(rowData) {
		const url = "/suggestion/" + rowData.id;

		return <Link to={url}>{rowData.suggestion_number}</Link>
	}

	getSuggestionDateCreated(rowData) {
		return formatDate(rowData.date_reported, true);
	}

	getMaterialCode(rowData) {
		const url = "/product/" + rowData.material_id;

		return <>
			<Tooltip autoHide={false} target=".product-code-tooltip" />
			<div data-pr-tooltip={rowData.material_description} data-pr-position="left" className="d-block product-code-tooltip" key={rowData.material_description}><Link to={url}>{rowData.material_sku}</Link><span className="badge bg-primary text-white ms-2">x {rowData.material_qty}</span></div>
		</>
	}
 
	getProductCode(rowData) {
		const url = "/product/" + rowData.product_id;

		return <>
			<Tooltip autoHide={false} target=".product-code-tooltip" />
			<div data-pr-tooltip={rowData.product_description} data-pr-position="left" className="d-block product-code-tooltip" key={rowData.product_description}><Link to={url}>{rowData.product_sku}</Link></div>
		</>
	}

	getScrapDate(rowData) {
		return formatDate(rowData.date_reported, true);
	}

	render() {
		const { isLoaded, traveller, scrap, number, name, expandedRows, suggestions } = this.state;

		return (
			<>
				<div className="edit-order-bar position-sticky top-0 p-3">
					<div className="container px-0 d-flex justify-content-between align-items-center">
						<div className="d-flex justify-content-start align-items-center">
							<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.onSidebarOpen(true);}}>
								<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
							</Button>
							<div className="d-flex">
								{
									!isLoaded && (
										<div>
											<i className="pi pi-spin pi-spinner" style={{ fontSize: '2.8rem' }}></i>
										</div>
									)
								}

								{
									isLoaded && (<>
										<h1 className="mb-0 h3 mb-1">Viewing Employee: {name} #{number}</h1>
										<div></div>
									</>)
								}
							</div>
						</div>
					</div>
				</div>
				{
					isLoaded && (
					<>
						{
							<div className="container mt-5" style={{maxWidth: '1200px'}}>
								<div className="row mb-5">
									<div className="col-12">
										<HourBookings employeeId={this.props.id} employeHours={this.state.basicHours} bookingEmployeeId={this.props.bookingEmployeeId} capabilities={this.props.capabilities} />
									</div>
								</div>
							</div>
						}
						<form className="container px-xxl-0 mt-5">
							{
								traveller.length > 0 && (
									<div className="row">
										<div className="col-12">
											<h2 className="h4 mb-3">Traveller Activities</h2>
											<div className="card mb-5">
												<DataTable value={traveller} expandedRows={expandedRows} onRowToggle={(e) => this.setState({expandedRows: e.data})} rowExpansionTemplate={this.rowExpansionTemplate.bind(this)} paginator rows={10}>
													<Column expander={true} style={{ width: '3rem' }} />
													<Column key="traveller_number" field="traveller_number" header="Traveller #" sortable={true} body={this.getTravellerNumber} />
													<Column key="number" field="number" header="Order #" sortable={true} body={this.getOrderNumber} />
													<Column key="order_company" field="order_company" header="Customer" sortable={true} />
													<Column key="order_ac_number" field="order_ac_number" header="A/C" sortable={true} />
													<Column key="product_title" field="product_title" header="Product" sortable={true} style={{minWidth: '10rem'}} body={this.getProductSku} />
													<Column key="total_target_time" field="total_target_time" header="Total Target Time" body={this.getTotalTargetTime} />
													<Column key="time" field="time" header={this.getTotalRecordedTimeHeader} body={this.getTimeSpent} />
													<Column key="status" field="status" header="Status" style={{minWidth: '10rem'}} body={this.getTravellerStatus} />
												</DataTable>
											</div> 
										</div>
									</div>	
								)
							}
							{
								scrap.length > 0 && (
									<div className="row">
										<div className="col-12">
											<h2 className="h4 mb-3">Scraps</h2>
											<div className="card mb-5">
												<DataTable value={scrap} paginator rows={10}>
													<Column key="scrap_number" field="scrap_number" header="Scrap #" body={this.getScrapNumber} sortable={true} />
													<Column key="date_reported" field="date_reported" header="Date" body={this.getScrapDate} sortable={true} />
													<Column key="traveller_number" field="traveller_number" header="Traveller #" body={this.getTravellerNumber} sortable={true} />
													<Column key="product_name" field="product_name" header="Product" body={this.getProductCode} style={{minWidth: '10rem'}} sortable={true} />
													<Column key="material_code" field="material_code" header="Material" body={this.getMaterialCode} style={{minWidth: '10rem'}} sortable={true} />
													<Column key="issue" field="issue" header="Reason" sortable={true} />
												</DataTable>
											</div> 
										</div>
									</div>	
								)
							}
							{
								suggestions.length > 0 && (
									<div className="row">
										<div className="col-12">
											<h2 className="h4 mb-3">Suggestions</h2>
											<div className="card mb-5">
												<DataTable value={suggestions} paginator rows={10}>
													<Column key="suggestion_number" field="suggestion_number" header="Suggestion #" sortable={true} body={this.getSuggestionNumber} />
													<Column key="date_reported" field="date_reported" header="Date" body={this.getSuggestionDateCreated} sortable={true} />
													<Column key="traveller_number" field="traveller_number" header="Traveller" body={this.getTravellerNumber} sortable={true} />
													<Column key="status" field="status" header="Status" body={this.getStatus} sortable={true} />
													<Column key="suggestion" field="suggestion" header="Suggestion" sortable={false} />
												</DataTable>
											</div> 
										</div>
									</div>
								)
							}
							{
								<div className="row mb-5">
									<div className="col-12">
										<EmployeeHours employeeId={this.props.id} employeHours={this.state.basicHours} bookingEmployeeId={this.props.bookingEmployeeId} capabilities={this.props.capabilities} />
									</div>
								</div>
							}
						</form> 
					</>
				)
			}
			</>
    );
  }
}
export default EditScrapForm;