import React, { useState, useEffect, useRef } from 'react';

import { Calendar } from 'primereact/calendar';

import axios from 'axios';
import { getUrl } from '../planner/planner';
import { Fieldset } from 'primereact/fieldset';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';

export function EmployeeHours(props) {
	const [workingHours, setWorkingHours] = useState([]);

	function canEditHours() {
		if(props.capabilities.edit_employees) {
			return true;
		} else {
			return false;
		}
	}

	async function getWorkingHours() {
		if(workingHours.length === 0) {
			await axios.get(getUrl() + '/wp-json/planner/v1/employees/' + props.employeeId, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then(res => {
				if(res.data.basic_hours.length != 0) {
					setWorkingHours(res.data.basic_hours)
				} else {
					setWorkingHours([
						{
							day: 'Monday',
							start: null,
							end: null,
							break_morning: null,
							break_lunch: null,
							break_afternoon: null
						},
						{
							day: 'Tuesday',
							start: null,
							end: null,
							break_morning: null,
							break_lunch: null,
							break_afternoon: null
						},
						{
							day: 'Wednesday',
							start: null,
							end: null,
							break_morning: null,
							break_lunch: null,
							break_afternoon: null
						},
						{
							day: 'Thursday',
							start: null,
							end: null,
							break_morning: null,
							break_lunch: null,
							break_afternoon: null
						},
						{
							day: 'Friday',
							start: null,
							end: null,
							break_morning: null,
							break_lunch: null,
							break_afternoon: null
						}
					])
				}
			})
			.catch(err => console.log(err));	
		}
	}

	getWorkingHours();

	function saveWorkingHours() {
		axios.put(getUrl() + '/wp-json/planner/v1/employees/' + props.employeeId, {
			"basic_hours" : workingHours
		}, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then().catch(err => console.log(err));
	}

	function editDeduction(e, index, breakTime) {
		let _workingHours = [...workingHours];

		_workingHours[index][breakTime] = e.value;

		setWorkingHours(_workingHours);

		saveWorkingHours();
	}

	function editStartTime(e, index) {
		let _workingHours = [...workingHours];

		_workingHours[index]['start'] = e.value;

		setWorkingHours(_workingHours);

		saveWorkingHours();
	}

	function editEndTime(e, index) {
		let _workingHours = [...workingHours];

		_workingHours[index]['end'] = e.value;

		setWorkingHours(_workingHours);

		saveWorkingHours();
	}

	function getLunch(rowData, rowProps) {
		return <InputNumber disabled={!canEditHours()} value={rowData.break_lunch} onChange={(e) => {editDeduction(e, rowProps.rowIndex, 'break_lunch'); }} />
	}

	function getAfternoonBreak(rowData, rowProps) {
		return <InputNumber disabled={!canEditHours()} value={rowData.break_afternoon} onChange={(e) => {editDeduction(e, rowProps.rowIndex, 'break_afternoon'); }} />
	}

	function getMorningBreak(rowData, rowProps) {
		return <InputNumber disabled={!canEditHours()} value={rowData.break_morning} onChange={(e) => {editDeduction(e, rowProps.rowIndex, 'break_morning'); }} />
	}

	function getStartTime(rowData, rowProps) {
		let startDate = '';

		if(rowData.start) {
			startDate = new Date(rowData.start);
		}

		return <Calendar disabled={!canEditHours()} value={startDate} timeOnly onChange={(e) => {editStartTime(e, rowProps.rowIndex); }} stepMinute={30} />
	}

	function getEndTime(rowData, rowProps) {
		let endDate = '';

		if(rowData.end) {
			endDate = new Date(rowData.end);
		}

		return <Calendar disabled={!canEditHours()} value={endDate} timeOnly onChange={(e) => {editEndTime(e, rowProps.rowIndex); }} stepMinute={30} />
	}

	return (<>
		<Fieldset legend="Working Hours">
			<DataTable value={workingHours} tableStyle={{ minWidth: '50rem' }}>
				<Column field="day" header="Day"></Column>
				<Column field="start_time" body={getStartTime} header="Start"></Column>
				<Column field="end_time" body={getEndTime} header="End"></Column>
				<Column field="morning_break" header="Morning Break" body={getMorningBreak}></Column>
				<Column field="lunch" header="Lunch" body={getLunch}></Column>
				<Column field="afternoon_break" header="Afternoon Break" body={getAfternoonBreak}></Column>
			</DataTable>
		</Fieldset>
	</>)
}