import React, { Component } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode } from 'primereact/api';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { formatDate, formatTrafficDate, getJobTimeBadge, getUrl } from '../planner/planner';
import { decode } from 'html-entities';

export class Orders extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orders: [],
			title: null,
			documentTitle: null,
			jobCount: null,
			isLoaded: false,
			lastSynced: null,
			selectedStatus: null,
			order_statuses: [],
			filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				status: { value: null, matchMode: FilterMatchMode.CONTAINS }
			},
			globalFilterValue: '',
			selectedStatusFilter: null,
			shouldUpdate: false,
			expandedRows: [],
			columns: [
				{ field: 'date_created', header: 'Created Date', sortable: true },
				{ field: 'company_name', header: 'Customer', sortable: true },
				{ field: 'account_reference', header: 'A/C' },
				{ field: 'company_reference', header: 'Customer Order #', sortable: true },
				{ field: 'status', header: 'Status', sortable: true },
				{ field: 'account_manager', header: 'A/C Manager', sortable: true },
				{ field: 'line_items', header: 'Products', sortable: false },
				{ field: 'kitted', header: 'Kitted', sortable: false },
				{ field: 'job_time', header: 'Job Time', sortable: false },
				{ field: 'customer_requested_date', header: 'Due Date', sortable: true },
				{ field: 'cell_pack_confirmed_date', header: 'Confirmed Date', sortable: true }
			],
			visibleColumns: null,
			isShortage: this.props.status,
			capabilities: this.props.capabilities,
			showError: false,
			toast: React.createRef(),
			errorToast: React.createRef()
		}

		if(this.props.capabilities.read_processes === true) {
			this.setState({
				columns: this.state.columns
			})

			let _columns = []; 
			
			if(_columns.length != 0) {
				_columns = { ...this.state.columns };
			}

			_columns.push(
				{ field: 'job_time', header: 'Target Time', sortable: false },
			)

			this.setState({
				columns: _columns
			});
		}

		this.editOrderButton = this.editOrderButton.bind(this);
		this.getProducts = this.getProducts.bind(this);
		this.getStatus = this.getStatus.bind(this);
		this.getAccountReference = this.getAccountReference.bind(this);
		this.getNoteAuthor = this.getNoteAuthor.bind(this);
	}

	/**
	 * When the component is created get the data from the REST API
	 */
	componentDidMount() {
		this.setState({
			orders: [],
			jobCount: null
		});

		this.updateOrders();
		this.clearFilters();

		// If the link is filtering from the url get the data from the query string
		const queryParams = new URLSearchParams(window.location.search);
    const customer = queryParams.get('filter');

		if(customer !== null) {
			// If filtering from the url set the global value
			let _filters = { ...this.state.filters };

			_filters['global'].value = customer;

			this.setState({
				globalFilterValue: customer,
				filters: _filters
			});
		}

		// If the user has selected custom columns, pre load these or use the default.
		if(localStorage.getItem('visibleColumns')) {
			this.setState({
				visibleColumns: JSON.parse(localStorage.getItem('visibleColumns'))
			});
		} else {
			this.setState({
				visibleColumns: this.state.columns
			});
		}
	}

	/**
	 * Gets the orders for the DataTable
	 */
	updateOrders() {
		if(this.props.capabilities.read_shop_orders === true || this.props.capabilities.edit_shop_orders === true) {
			var url = getUrl() + '/wp-json/planner/v1/orders';

			if(!this.props.customerId && !this.props.companyId) {
				if(this.props.status) { 
					url += '?jobs=' + this.props.status;
				}
			} else {
				if(this.props.customerId) {
					url += '?customers=' + this.props.customerId;
				}

				if(this.props.companyId) {
					url += '?companies=' + this.props.companyId;
				}
			}

			// Get all the data and set the loaded to true
			axios.get(url, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then(res => {
				this.setState({
					orders: res.data.orders.orders,
					order_statuses: res.data.order_statuses,
					jobCount: res.data.orders.length,
					lastSynced: res.data.orders.orders_last_synced,
					isLoaded: true
				});

				if(res.data.orders.manufacturer_name) {
					this.setState({
						title: res.data.orders.manufacturer_name,
						documentTitle: res.data.orders.manufacturer_name,
					})
				}

				if(res.data.orders.customer_name) {
					this.setState({
						title: res.data.orders.customer_name,
						documentTitle: res.data.orders.customer_name,
					})
				}

				if(this.dt != undefined) {
					this.dt.reset();
				}
			}).catch(err => {
				this.state.errorToast.current.show({ severity: 'error', summary: 'Oops 🤦‍♂️', sticky: true, detail: 'An error has occurred, please contact IT support.' });
				console.log(err);
			});
		}
	}

	/**
	 * Checks if the component should update based on if an order status has changed or the props for the component is different
	 * 
	 * @param {Object} prevProps The previous state of the props
	 * @param {Object} prevState The previous state of the state
	 */
	componentDidUpdate(prevProps, prevState) {
		if(this.props.pageTitle !== prevProps.pageTitle || this.props.capabilities !== prevProps.capabilities || this.props.status !== prevProps.status || this.state.shouldUpdate !== prevState.shouldUpdate || this.props.priorityOnly !== prevProps.priorityOnly || this.props.customerId !== prevProps.customerId || this.props.companyId !== prevProps.companyId) {
			this.setState({
				orders: [],
				jobCount: null
			});

			this.updateOrders();
			this.clearFilters();

			this.setState({
				shouldUpdate: false,
				isLoaded: false
			});
		}
	}

	/**
	 * Create an array of all of the orders based on props from the component
	 * 
	 * @returns An array containing information for the Datatable
	 */
	getOrders() {
		const tableRows = [];

		this.state.orders.forEach(order =>
			{
				let array = {}
				if(this.props.accountCode == null || this.props.accountCode === order.ac_number) {
					if(this.props.manufacturer == null || order.manufacturer.find((element) => element == this.props.manufacturer) ) {
						if((this.props.priorityOnly === "true" && (order.priority === '2' || order.priority === '3' || order.priority === '4' || order.priority === '5')) || this.props.priorityOnly == null) {
							let priority = '';

							if(order.priority === '2' || order.priority === '3' || order.priority === '4' || order.priority === '5') {
								priority = order.priority;
							}
 
							array = {
								date_created: order.date_created,
								id: order.id,
								order_number: order.order_number,
								company_name: order.company_name,
								account_reference: order.ac_number,
								company_reference: order.cust_order_number,
								account_manager: order.account_manager_to_display,
								status: order.status_label,
								status_slug: order.status_name,
								products: order.products,
								travellers: order.travellers,
								bom: order.bom,
								kitted: order.kitted,
								customer_requested_date: order.date_order_due,
								cell_pack_confirmed_date: order.date_confirmed,
								date_completed: order.date_completed,
								date_cancelled: order.date_cancelled,
								flexibility: order.flexible,
								pushbacks: order.order_push_backs,
								isLocked: order.locked,
								notes: order.notes,
								total_target_time: order.target_time,
								priority: priority,
								shortage: this.props.shortage,
								extra_keywords: order.extra_keywords,
							}

							if(order.kitted) {
								array.kitted = Object.values(order.kitted);
							}

							tableRows.push(array)
						}
					}
				}
			}
		);

		return tableRows;
	}

	/**
	 * Create a button which links to the individual order page
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns A button linking to view the order
	 */
	editOrderButton(rowData) {
		const url = "/job/" + rowData['id'];
		let icon = <i className="pi pi-lock me-1" style={{ fontSize: '0.8rem' }}></i>;
		let text = 'View';

		// Check if the current row is locked, change the icon and text based on if it is or not
		if((rowData.isLocked === false || rowData.isLocked.ID === this.props.currentLoggedInUser.id) && this.props.capabilities.edit_shop_orders === true) {
			if(rowData.status !== 'completed' && rowData.status !== 'cancelled') {
				icon = <i className="pi pi-pencil me-1" style={{ fontSize: '0.8rem' }}></i>;
				text = 'Edit';
			}
		}

		return (<Link to={url} className="btn btn-primary">{icon} {text}</Link>);
	}

	/**
	 * Gets the order status and displays in a badge with correct colouring
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns The order status inside of a bootstrap badge
	 */
	getStatus(rowData) {
		// Replace all - with a space so it's readable to the users instead of as a slug
		let text = rowData.status.replaceAll('-', ' ');

		// Create empty class string to be populated depending on the status // Todo - Cut down using bg-{status}?
		let classes = "bg-" + rowData.status_slug;

		// Split the text up and add a capital letter to the start of each word
		const newText = text.split(" ");

		// Set the first letter of the status to a capital
		for (var i = 0; i < newText.length; i++) {
			newText[i] = newText[i].charAt(0).toUpperCase() + newText[i].slice(1);
		}

		text = newText.join(" ");

		if(text === 'Processing') {
			text = 'In Production';
		}

		if(text === 'Pending') {
			text = 'Inputted';
		}

		return (<span className={"badge " + classes}>{rowData.status}</span>)
	}

	/**
	 * List all of the products and their quantities
	 * 
	 * @param {Object} rowData The data associated to the row 
	 * @returns A list of products and the quantity for the order
	 */
	getProducts(rowData) {
		if(this.props.capabilities.read_products === true) {
			return (
				<>
					<Tooltip autoHide={false} target=".custom-target-icon" />
					{
						rowData.products && (
							rowData.products.map(line_item =>
								<div data-pr-tooltip={line_item.product_name} data-pr-position="left" className="d-block custom-target-icon" key={line_item.product_name}><Link to={"/product/" + line_item.product_id}>{line_item.product_sku}</Link> <span className="badge badge-primary bg-primary">x {line_item.product_qty}</span></div> 
							)
						)
					}
				</>
			)
		} else {
			return (
				<>
					<Tooltip autoHide={false} target=".custom-target-icon" />
					{
						rowData.products && (
							rowData.products.map(line_item =>
								<div data-pr-tooltip={line_item.product_name} data-pr-position="left" className="d-block custom-target-icon" key={line_item.product_name}>{line_item.product_sku} <span className="badge badge-primary bg-primary">x {line_item.product_qty}</span></div> 
							)
						)
					}
				</>
			)
		}
	}

	getKitted(rowData) {
		if(rowData.kitted) {
			return rowData.kitted.map(bom => {
				let dangerClass = false;

				return (
					<div>
						<Tooltip autoHide={false} target={'.custom-tooltip-btn-' + bom.material_cat_name.split(' ').join('-') + '-' + rowData.id}>
							{
								bom.tooltip.map(bom => {
									if(bom.date_due) {
										dangerClass = true;
									}

									return (
										<p className="my-0" style={{fontSize: '0.85rem'}}>{bom.material_sku}: {bom.material_kitted}/{bom.material_qty} {
											bom.date_due && (
												<span className="badge bg-danger text-white" style={{fontSize: '0.65rem'}}>{bom.date_due}</span>
											)
										}</p>
									)
								})
							}
						</Tooltip>
						<div className={'custom-tooltip-btn-' + bom.material_cat_name.split(' ').join('-') + '-' + rowData.id}>{bom.material_cat_name} <div className={"badge bg-" + ((dangerClass) ? 'danger' : 'primary') + " text-white"}>{bom.material_cat_percentage}</div></div>
					</div>
				)
			});
		} else {
			return '';
		}
	}

	getTravellers(rowData) {
		return (<>
		<Tooltip autoHide={false} target=".custom-target-icon"  />
		{
			rowData.travellers != undefined && rowData.travellers != false && (
				rowData.travellers.map((line_item) => 
					<div data-pr-tooltip={line_item.description} data-pr-position="left" className="d-block custom-target-icon" key={line_item.name}><Link to={"/traveller/" + line_item.id}>{line_item.name}</Link> <span className="badge badge-primary bg-primary">x {line_item.product_qty}</span></div> 				
				)
			)
		}
		{
			rowData.travellers == undefined || rowData.travellers == false && (
				<span>N/A</span>
			)
		}
		</>)
	}

	/**
	 * Get the total time associated to the job
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns The total time of the job
	 */
	getJobTime(rowData) {
		return getJobTimeBadge(rowData.total_target_time, null, 'No Time', 'bg-dark');
	}

	/**
	 * Checks if the customer date is flexible or not and outputs small text below the date if they are
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns HTML for the customer requested date
	 */
	getRequestedDate(rowData) {
		if(rowData.customer_requested_date) {
			return(
				<div>
					<div>{formatDate(rowData.customer_requested_date)}</div>
					{
						rowData.flexibility && (
							<div className="d-flex"><small className="text-lowercase">{rowData.flexibility}</small></div>
						)
					}
				</div>
			)
		} else {
			return <span className="badge bg-danger text-white">Missing Date</span>
		}
	}

	/**
	 * Checks if there's any pushbacks on the order
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns HTML for the cellpack confirmed date
	 */
	getConfirmedDate(rowData) {
		if(rowData.cell_pack_confirmed_date) {
			return(
				<div>
					<div>{formatTrafficDate(rowData.cell_pack_confirmed_date)}</div>
					{
						rowData.pushbacks && (
							<div className="d-flex"><small>{rowData.pushbacks} Pushback{(rowData.pushbacks > 1 ? 's' : '')}</small></div>
						)
					}
				</div>
			);
		} else {
			return <span className="badge bg-danger text-white">Missing Date</span>
		}
	}

	/**
	 * Checks if there's any pushbacks on the order
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns HTML for the cellpack confirmed date
	 */
	getCompletedDate(rowData) {
		return(
			<div>
				<div>{formatTrafficDate(rowData.date_completed, 'Completed', 'bg-success')}</div>
			</div>
		);
	}

	/**
	 * Checks if there's any pushbacks on the order
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns HTML for the cellpack confirmed date
	 */
	getCancelledDate(rowData) {
		return(
			<div>
				<div>{formatTrafficDate(rowData.date_cancelled, 'Cancelled', 'bg-danger')}</div>
			</div>
		);
	}

	/**
	 * Clears the global and status filters
	 */
	clearFilters() {
		let _filters = { ...this.state.filters };

		_filters['status'].value = null;
		_filters['global'].value = null;

		this.setState({
			globalFilterValue: '',
			selectedStatusFilter: null,
			filters: _filters
		});
	}

	clearSearch() {
		let _filters = { ...this.state.filters };

		_filters['global'].value = null;

		this.setState({
			globalFilterValue: '',
			filters: _filters
		})
	}

	/**
	 * Filter out statuses that cannot be filtered
	 * 
	 * @returns Statuses that should be able to be filtered
	 */
	getFilterStatuses() {
		let statuses = [];

		this.props.availableStatuses.forEach((status) => {
			if(status.name !== 'Completed' && status.name !== 'Cancelled') {
				statuses.push(status);
			}
		})

		return statuses
	}

	/**
	 * Create the header for the DataTable so filtering is possible
	 * 
	 * @returns The DataTable header
	 */
	renderHeader() {
		return (
			<div className="container">
				<div className="d-flex justify-content-end">
					<div className="sales-order-header">
						{
							this.state.selectedStatusFilter && !this.props.singleStatus && (
								<Button type="button" label="Reset" onClick={this.clearFilters.bind(this)} className="me-2" />
							)
						}
						{
							<MultiSelect disabled={!this.state.isLoaded} placeholder="Select Job Columns" value={this.state.visibleColumns} options={this.state.columns} onChange={this.onColumnToggle.bind(this)} optionLabel="header" className="me-2 columnToggling" display="chip" />
						}
						{
							!this.props.singleStatus && this.isStatusShown() && (
								<Dropdown
								options={this.state.order_statuses}
								optionLabel="label" value={this.state.selectedStatusFilter}
								placeholder="Status Filter"
								className="me-2"
								onChange={this.onStatusFilterChange.bind(this)}
								itemTemplate={this.statusFilterTemplate}
								disabled={!this.state.isLoaded}
								valueTemplate={this.statusFilterValue} />
							)
						}
						<span className="p-input-icon-left p-input-icon-right">
							<i className="pi pi-search" />
							<InputText placeholder="Search..." value={this.state.globalFilterValue} onChange={this.onGlobalFilterChange.bind(this)} disabled={!this.state.isLoaded} />
							{
								this.state.globalFilterValue != null && this.state.globalFilterValue !== '' && (
									<i className="pi pi-times" onClick={this.clearSearch.bind(this)} />
								)
							}
						</span>
					</div>
				</div>
			</div>
		);
	}

	/**
	 * Update the global filtering to filter table contents
	 * 
	 * @param {input} e Information related to the current input
	 */
	onGlobalFilterChange(e) {
		const value = e.target.value;

		let _filters = { ...this.state.filters };

		_filters['global'].value = value;

		this.setState({filters: _filters, globalFilterValue: value })
	}

	/**
	 * Update the status filtering based on what the user has selected
	 * 
	 * @param {input} e Information related to the current input
	 */
	onStatusFilterChange(e) {
		const value = e.target.value;

		let _filters = { ...this.state.filters };

		_filters['status'].value = value.label;

		this.setState({filters: _filters, selectedStatusFilter: value })
	}

	/**
	 * Return the current index of the note
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @param {Object} props The properties associated to the row
	 * @returns The current row number (+1 as rows start from 0)
	 */
	getNoteNumber(rowData, props) {
		return rowData.notes - props.rowIndex;
	}

	/**
	 * Get the author who added the note
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns The author who added the note
	 */
	getNoteAuthor(rowData) {
		if(rowData.added_by_id != 0 && this.props.capabilities.edit_users) {
			return <Link to={'/employee/' + rowData.added_by_id}>{rowData.added_by}</Link>
		} else {
			return rowData.added_by;
		}
	}

	getNoteContent(rowData) {
		return decode(rowData.content);
	}

	/**
	 * Generate the template for the functionality when a user clicks to expand a row.
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns The template for the row epansion which includes all of the notes for the order
	 */
	rowExpansionTemplate(rowData) {
		const notes = [];

		if(rowData.notes) {
			// Loop through all notes on the order
			rowData.notes.map(order =>
				notes.push({
					id: order.user_id,
					date_created: formatDate(order.comment_date, true),
					content: order.comment_content,
					added_by: order.comment_author,
					added_by_id: order.user_id,
					notes: rowData.notes.length
				})
			);
		}

		return (
			<DataTable value={notes} className="mx-5 mt-2" paginator rows={10}>
				<Column field="id" header="Note #" body={this.getNoteNumber} headerStyle={{ width: '6rem' }}></Column>
				<Column field="date_created" header="Date" headerStyle={{ width: '10rem' }}></Column>
				<Column field="content" header="Note" body={this.getNoteContent}></Column>
				<Column field="added_by" header="Author" body={this.getNoteAuthor} headerStyle={{ width: '8rem' }}></Column>
			</DataTable>
		);
	}

	/**
	 * Sets the state for the selected columns and saves in local storage so it's remembered for future sessions.
	 * 
	 * @param {Event} event The selected column
	 */
	onColumnToggle(event) {
		let selectedColumns = event.value;
		let orderedSelectedColumns = this.state.columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

		this.setState({visibleColumns: orderedSelectedColumns});
		localStorage.setItem('visibleColumns', JSON.stringify(orderedSelectedColumns));
	}

	/**
	 * Gets the order number and count of notes to be displayed under the sales order number
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns HTML For the order number including the note count
	 */
	getOrderNumber(rowData) {
		let noteCount = 0;

		if(rowData.notes.length) {
			noteCount = rowData.notes.length
		}

		return <><p className="mb-0 d-flex align-items-center">{rowData.order_number} </p><div className="d-flex"><small>({noteCount} Notes)</small></div></>;
	}

	getAccountReference(rowData) {
		return <Link to="#" onClick={() => {
			let _filters = { ...this.state.filters };

			_filters['global'].value = rowData.account_reference;

			this.setState({
				globalFilterValue: rowData.account_reference,
				filters: _filters
			})
		}}>{rowData.account_reference}</Link>;
	}

	getPriority(rowData) {
		let badgeText = '';

		for(let i = 0; Number(rowData.priority) > i; i++) {
			badgeText += '!';
		}

		return <span className={"priority-only-badge badge bg-priority-" + rowData.priority + " text-white ms-1"} style={{fontSize: '0.8em'}}>{badgeText}</span>
	}

	/**
	 * Gets the date for the order
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns HTML For the order date formatted correctly
	 */
	getDateCreated(rowData) {
		return formatDate(rowData.date_created);
	}

	/**
	 * Toggle the expanded rows for the jobs
	 */
	toggleExpandRows() {
		let _expandedRows = {};

		if(this.state.expandedRows.length === 0) {
			this.state.orders.forEach((p) => (_expandedRows[`${p.id}`] = true));

			this.setState({
				expandedRows: _expandedRows
			});
		} else {
			this.setState({
				expandedRows: []
			});
		}
	}

	statusFilterTemplate(option) {
		return (<span className={"badge bg-" + option.name}>{option.label}</span>)
	}

	statusFilterValue(option, props) {
		if(option) {
			return (<span className={"badge bg-" + option.name}>{option.label}</span>)
		}

		return <span>{props.placeholder}</span>;
	}

	isStatusShown() {
		let shown = false;

		if(this.state.visibleColumns) {
			this.state.visibleColumns.map((col) => {
				if(col.field === 'status') {
					shown = true;
				}
			});
		}

		return shown;
	}

	render() {
		if(this.state.documentTitle) {
			document.title = this.state.documentTitle + ' - Planner';
		} else {
			document.title = this.props.pageTitle + ' - Planner';
		}

		const { errorToast, toast, isLoaded, expandedRows, statusUpdated, filters, visibleColumns } = this.state;
		let title;

		if(this.props.title) {
			title = this.props.title;
		} else if(this.state.title) {
			title = 'Jobs: ' + this.state.title;
		}

		let synced = <span><strong>Last Synced:</strong>&nbsp;{formatDate(this.state.lastSynced, true)}</span>

		if(this.props.capabilities.read_shop_orders !== true && this.props.capabilities.edit_shop_orders !== true) {
			title = 'Access Denied';
			synced = '';
		}

		let loadingClass = 'isLoading';

		if(isLoaded) {
			loadingClass = 'notLoading';
		}

		let jobCount = this.state.jobCount;

		if(jobCount == undefined) {
			jobCount = this.getOrders().length;
		}

		return (
			<>
				<div className="edit-order-bar position-sticky top-0 py-3 mb-5">
					<div className="mx-5 px-0 d-flex justify-content-between align-items-center w-100" style={{maxWidth: '100%'}}>
						<div className="d-flex align-items-center">
							<div>
								<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.updateSidebarOpened(true);}}>
									<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
								</Button>
							</div>
							<div>
							{
									this.props.capabilities.length == 0 && (
										(
											<div className={"mx-5"}>
												<i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }}></i>
											</div>
										)
									)
								}
								{
									this.props.capabilities.length != 0 && (
										<>
											<h1 className="mb-0 h3">{title}</h1>
											<strong>Job Count: </strong>{jobCount} of {this.getOrders().length}
										</>
									)
								}
							</div>
						</div>
						<div>{synced}</div>
					</div>
				</div>
				<Toast ref={toast} position="bottom-right" />
				<Toast ref={errorToast} position="center" />
				{
					(this.props.capabilities.read_shop_orders === true || this.props.capabilities.edit_shop_orders === true) && visibleColumns !== null && ( // https://stackoverflow.com/questions/70682832/
					<>
						<Tooltip autoHide={false} target=".requested-date" />
						<div className="d-flex justify-content-between mx-5 mb-3">
							<div className="px-0 d-flex me-3">
								<strong>Priority: </strong>
									<div className="requested-date ms-2 bg-priority-2" data-pr-tooltip="Level 2 Priority"></div>
									<div className="requested-date ms-2 bg-priority-3" data-pr-tooltip="Level 3 Priority"></div>
									<div className="requested-date ms-2 bg-priority-4" data-pr-tooltip="Level 4 Priority"></div>
									<div className="requested-date ms-2 bg-priority-5" data-pr-tooltip="Level 5 Priority"></div>
								</div>
							<div className="px-0 d-flex">
								<strong>Date: </strong>
								<div className="requested-date green ms-2 bg-success" data-pr-tooltip="Over a week away" data-pr-position="left"></div>
								<div className="requested-date yellow ms-2 bg-warning" data-pr-tooltip="Within the next week" data-pr-position="left"></div>
								<div className="requested-date red ms-2 bg-danger" data-pr-tooltip="Today or late" data-pr-position="left"></div>
							</div>
						</div>
						<div className={"card mx-5 mb-3"}>
							{
							!isLoaded && (
								<div className={"mx-5 mb-3 d-flex align-items-center justify-content-center h-100 w-100"} style={{ position: 'absolute', zIndex: '99999' }}>
									<i className="pi pi-spin pi-spinner" style={{ fontSize: '6rem', height: '6rem' }}></i>
								</div>
							)
							}
							<DataTable onFilter={DataTableStateEvent => console.log(DataTableStateEvent)} stateStorage="local" className={loadingClass} ref={(el) => this.dt = el} onValueChange={filteredData => this.setState({jobCount: filteredData.length})} expandedRows={expandedRows} onRowToggle={(e) => this.setState({expandedRows: e.data})} rowExpansionTemplate={this.rowExpansionTemplate.bind(this)} globalFilterFields={['extra_keywords', 'order_number', 'company_name', 'company_reference', 'date_created', 'account_manager', 'account_reference']}  key={statusUpdated} filters={filters} dataKey="id" size="small" value={this.getOrders()} paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100]} sortMode="multiple" header={this.renderHeader(this)}>
								<Column expander={true} style={{ width: '3rem' }} />
								<Column key="priority" field="priority" body={this.getPriority} sortable header="Priority" />
								<Column key="id" field="id" header="Order #" sortable={true} body={this.getOrderNumber} />
								{visibleColumns.map((col) => {
									// Absolute shit code but LocalStorage won't store the body functions. See if there's a better way to do this.
									if(col.field === 'line_items') {
										if((this.props.capabilities.edit_travellers || this.props.capabilities.read_travellers) && this.props.status === 'processing') {
											return (<Column key={'travellers'} field={'travellers'} header={'Travellers'} sortable={col.sortable} body={this.getTravellers} style={{minWidth: '10rem'}} />)
										} else {
											return (<Column key={'line_items'} field={col.field} header={col.header} sortable={col.sortable} body={this.getProducts} style={{minWidth: '10rem'}} />)
										}
									} else if(col.field === 'job_time') {
										if(this.props.capabilities.read_processes) {
										if(this.props.status !== 'kitted') {
											return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getJobTime} />)
										} else {
											return '';
										}
										} else {
											return '';
										}
									} else if(col.field === 'kitted') {
										if(this.props.status === 'kitted') {
											return (<Column key={'line_items'} field={'kitted'} header={'Kitted'} sortable={col.sortable} style={{minWidth: '10rem'}} body={this.getKitted} />)
										} else {
											return '';
										}
									} else if(col.field === 'account_reference') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getAccountReference} />)
									} else if(col.field === 'date_created') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getDateCreated} />)
									} else if(col.field === 'customer_requested_date') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getRequestedDate} />)
									} else if(col.field === 'cell_pack_confirmed_date') {
										if(this.props.status !== 'completed' && this.props.status !== 'cancelled') {
											return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getConfirmedDate} />)
										} else {
											if(this.props.status === 'completed') {
												return (<Column key={'completed_date'} field={'completed_date'} header={'Completed Date'} sortable={col.sortable} body={this.getCompletedDate} />)
											} else {
												return (<Column key={'cancelled_date'} field={'cancelled_date'} header={'Cancelled Date'} sortable={col.sortable} body={this.getCancelledDate} />)
											}
										}
									} else if(col.field === 'status') {
										if(!this.props.singleStatus) {
											return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getStatus} />)
										} else {
											return ''; // Prevent webpack compiller warning by returning empty
										}
									} else {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} />)
									}
								})}
								<Column body={this.editOrderButton} header="" style={{ minWidth: '6.2rem' }} />
							</DataTable>
						</div>
					</>
					)
				}
			</>
		);
	}
}
export default Orders