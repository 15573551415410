import React, { Component } from 'react';
import axios from 'axios';

import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';

import { Toast } from 'primereact/toast';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getUrl } from '../planner/planner';

export class Employees extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			employees: [],
			isLoaded: false,
			binDialog: false,
			toast: React.createRef()
		}
	}

	/**
	 * Checks if the component should update based on if an order status has changed or the props for the component is different
	 * 
	 * @param {Object} prevProps The previous state of the props
	 * @param {Object} prevState The previous state of the state
	 */
	componentDidUpdate(prevProps, prevState) {
		if(this.props.role !== prevProps.role) {
			this.updateEmployees();
		}
	}

	/**
	 * Gets the employees for the DataTable
	 */
	updateEmployees() {
		axios.get(getUrl() + '/wp-json/planner/v1/employees?role=' + this.props.role, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => this.setState({
			employees: res.data,
			isLoaded: true
		})).catch(err => {
			this.state.errorToast.current.show({ severity: 'error', summary: 'Oops 🤦‍♂️', sticky: true, detail: 'An error has occurred, please contact IT support.' });

			console.log(err)
		});
	}

	/**
	 * When the component is loaded
	 */
	componentDidMount() {
		document.title = 'Employees - Planner'

		this.updateEmployees();
	}

	/**
	 * If the employee is a operator output a button to let the user view their statistics
	 * 
	 * @param {object} rowData The data for the row
	 * @returns Return HTML for the link to view the employee if they're a viewable role
	 */
	getViewButton(rowData) {
		const url = "/employee/" + rowData.id;

		if(rowData.employee_role_name == 'Production Operator') {
			return (<Link to={url} className="btn btn-primary">View</Link>);
		}
	}

	render() {
		const { errorToast, isLoaded, employees } = this.state;

		let loadingClass = 'isLoading';

		if(isLoaded) {
			loadingClass = 'notLoading';
		}
 
		return (
			<>
				<Toast ref={errorToast} position="center" />
				<div className="edit-order-bar position-sticky top-0 py-3 mb-5" >
					<div className="mx-5 px-0 d-flex justify-content-between align-items-center w-100" style={{maxWidth: "100%"}}>
						<div className="d-flex align-items-center">
							<div>
								<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.updateSidebarOpened(true);}}>
									<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
								</Button>
							</div>
							<div>
							{
									isLoaded && (
										<>
											<h1 className="mb-0 h3">{this.props.title}</h1>
											<strong>Total Employees:</strong>&nbsp;{employees.length}
										</>
									)
								}
								{
									!isLoaded && (
										<>
											<div className={"mx-5"}>
												<i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }}></i>
											</div>
										</>
									)
								}
							</div>
						</div>
					</div>
				</div>
				{ 
					( // https://stackoverflow.com/questions/70682832/
						<div className="card mx-5 mb-5">
							{
								!isLoaded && (
									<div className={"mx-5 mb-3 d-flex align-items-center justify-content-center h-100 w-100"} style={{ position: 'absolute', zIndex: '99999' }}>
										<i className="pi pi-spin pi-spinner" style={{ fontSize: '6rem', height: '6rem' }}></i>
									</div>
								)
							}
							<DataTable className={loadingClass} dataKey="id" size="small" sortOrder={1} sortField="id" value={employees} tableStyle={{ minWidth: '50rem' }}>
								<Column key="employee_number" field="employee_number" header="Employee #" sortable={true} style={{maxWidth: '3rem'}} />
								<Column key="name" field="name" header="Name" sortable={true} />
								<Column key="employee_role_name" field="employee_role_name" header="Role" sortable={true} />
								<Column header="" body={this.getViewButton} />
							</DataTable>
						</div>
					)
				}
			</>
		);
	}
}
export default Employees