import React, { Component } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import { decode } from 'html-entities';

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';

import { Tooltip } from 'primereact/tooltip';


import { getUrl, formatDate } from '../planner/planner';

export class Scraps extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			scraps: [],
			isLoaded: false,
			binDialog: false,
			tableCount: 0,
			expandedRows: [],
			filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			},
			globalFilterValue: '',
			columns: [
				{ field: 'date', header: 'Reported Date', sortable: true },
				{ field: 'operator_name', header: 'Reported By', sortable: true },
				{ field: 'status_label', header: 'Status', sortable: false },
				{ field: 'material_sku', header: 'Material', sortable: true },
				{ field: 'material_average_cost_price', header: 'Material Cost Price', sortable: true },
				{ field: 'material_total_cost_price', header: 'Material Total Price', sortable: true },
				{ field: 'scrap_cat_name', header: 'Fault', sortable: true },
				{ field: 'product_sku', header: 'Product', sortable: true },
				{ field: 'traveller_number', header: 'Traveller #' },
				{ field: 'order_number', header: 'Order #', sortable: true },
				{ field: 'date_replaced', header: 'Date Replaced', sortable: true },
			],
			visibleColumns: [],
			errorToast: React.createRef()
		}

		this.getMaterial = this.getMaterial.bind(this);
		this.getScrapTitle = this.getScrapTitle.bind(this);
		this.getEditButton = this.getEditButton.bind(this);
	}

	/**
	 * Update the global filtering to filter table contents
	 * 
	 * @param {input} e Information related to the current input
	 */
	onGlobalFilterChange(e) {
		const value = e.target.value;

		let _filters = { ...this.state.filters };

		_filters['global'].value = value;

		this.setState({filters: _filters, globalFilterValue: value })
	}

	/**
	 * When the component isLoaded c = this.state;reated get the data from the REST API and set the columns based on the current users preferences
	 */
	componentDidMount() {
		let url = getUrl() + '/wp-json/planner/v1/scraps';

		if(this.props.status != undefined) {
			url = url + '?status=' + this.props.status;
		}

		axios.get(url, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => this.setState({
			scraps: res.data,
			tableCount: res.data.length,
			isLoaded: true
		})).catch(err => {
			this.state.errorToast.current.show({ severity: 'error', summary: 'Oops 🤦‍♂️', sticky: true, detail: 'An error has occurred, please contact IT support.' });

			console.log(err)
		});

		document.title = 'Scraps - Planner';

		// If the user has selected custom columns, pre load these or use the default.
		if(localStorage.getItem('visibleColumnsTraveller')) {
			this.setState({
				visibleColumns: JSON.parse(localStorage.getItem('visibleColumnsTraveller'))
			});
		} else {
			this.setState({
				visibleColumns: this.state.columns
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.pageTitle !== prevProps.pageTitle || this.props.status !== prevProps.status) {
			this.setState({
				scraps: [],
				tableCount: null
			});

			let url = getUrl() + '/wp-json/planner/v1/scraps';

			if(this.props.status != undefined) {
				url = url + '?status=' + this.props.status;
			}
	
			axios.get(url, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
			.then(res => this.setState({
				scraps: res.data,
				tableCount: res.data.length,
				isLoaded: true
			})).catch(err => {
				this.state.errorToast.current.show({ severity: 'error', summary: 'Oops 🤦‍♂️', sticky: true, detail: 'An error has occurred, please contact IT support.' });
	
				console.log(err)
			});
	
			document.title = 'Scraps - Planner';
	
			// If the user has selected custom columns, pre load these or use the default.
			if(localStorage.getItem('visibleColumnsTraveller')) {
				this.setState({
					visibleColumns: JSON.parse(localStorage.getItem('visibleColumnsTraveller'))
				});
			} else {
				this.setState({
					visibleColumns: this.state.columns
				});
			}

			this.setState({
				isLoaded: false
			});
		}
	}


	/**
	 * Get the material that has been scrapped with the quantity that's been scrapped
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {html} Get the material and make it clickable with the quantity in a badge next to each material
	 */
	getMaterial(rowData) {
		if(this.props.capabilities.edit_products === true) {
			return <>
				<Tooltip autoHide={false} target=".material_tooltip" />
				<p className="mb-0 material_tooltip" data-pr-tooltip={rowData.material_description} data-pr-position="left"><Link to={"/product/" + rowData.material_id}>{rowData.material_sku}</Link> <span className="badge bg-primary text-white">x {rowData.material_qty}</span></p>
			</>
		} else {
			return <>
				<Tooltip autoHide={false} target=".material_tooltip" />
				<p className="mb-0 material_tooltip" data-pr-tooltip={rowData.material_description} data-pr-position="left">{rowData.material_sku} <span className="badge bg-primary text-white">x {rowData.material_qty}</span></p>
			</>
		}
	}

	/**
	 * Get the traveller id and make it clickable to go to the traveller page
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {html} The traveller ID with a clickable link to go to that traveller page
	 */
	getTravellerNumber(rowData) {
		return <Link to={"/traveller/" + rowData.traveller_id}>{rowData.traveller_number}</Link>
	}

	/**
	 * Get the edit button for the scrap
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {html} The edit button for the scrap
	 */
	getEditButton(rowData) {
		const url = "/scrap/" + rowData.id;
		let icon = <i className="pi pi-lock me-1" style={{ fontSize: '0.8rem' }}></i>;
		let text = 'View';

		// Check if the current row is locked, change the icon and text based on if it is or not
		if(this.props.capabilities.edit_scraps == true) {
			icon = <i className="pi pi-pencil me-1" style={{ fontSize: '0.8rem' }}></i>;
			text = 'Edit';
		}

		return (<Link to={url} className="btn btn-primary">{icon} {text}</Link>);
	}

	/**
	 * Get the trunctated reason for the scrap
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {string} The truncated reason for the scrap
	 */
	getReason(rowData) {
		let suffix = '';

		if(rowData.issue.length > 50) {
			suffix = ' [...]';
		}

		return rowData.issue.substring(0, 50) + suffix;
	}

	/**
	 * Format the date for the scrap
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {string} The formatted date and time
	 */
	getDate(rowData) {
		return formatDate(rowData.date_reported, true);
	}

	/**
	 * Clear the global search filter
	 */
	clearSearch() {
		let _filters = { ...this.state.filters };

		_filters['global'].value = null;

		this.setState({
			globalFilterValue: '',
			filters: _filters
		})
	}

	/**
	 * Sets the state for the selected columns and saves in local storage so it's remembered for future sessions.
	 * 
	 * @param {Event} event The selected column
	 */
	onColumnToggle(event) {
		let selectedColumns = event.value;
		let orderedSelectedColumns = this.state.columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

		this.setState({visibleColumns: orderedSelectedColumns})
		localStorage.setItem('visibleColumns', JSON.stringify(orderedSelectedColumns));
	}

	/**
	 * Get the HTML to create the filtering options on the table header
	 * 
	 * @returns The HTML for the header for the table
	 */
	renderHeader() {
		return (
			<div className="container">
				<div className="d-flex justify-content-end">
					<div>
						{
							<MultiSelect disabled={!this.state.isLoaded} placeholder="Select Scrap Columns" value={this.state.visibleColumns} options={this.state.columns} onChange={this.onColumnToggle.bind(this)} optionLabel="header" className="me-2 columnToggling" display="chip" />
						}
						<span className="p-input-icon-left p-input-icon-right">
							<i className="pi pi-search" />
							<InputText disabled={!this.state.isLoaded} placeholder="Search..." value={this.state.globalFilterValue} onChange={this.onGlobalFilterChange.bind(this)} />
							{
								this.state.globalFilterValue !== null && this.state.globalFilterValue !== '' && (
									<i className="pi pi-times" onClick={this.clearSearch.bind(this)} />
								)
							}
						</span>
					</div>
				</div>
			</div>
		)
	}

	getScrapTitle() {
		if(this.props.capabilities.edit_travellers === true || this.props.capabilities.read_travellers === true) {
			return this.props.title;
		} else {
			if(this.props.capabilities.length != 0) {
				return 'Access Denied'
			} else {
				return (
					<div className={"mx-5"}>
						<i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }}></i>
					</div>
				)	
			}
		}
	}

	getOperator(rowData) {
		return <Link to={'/employee/' + rowData.reported_by_user_id}>{rowData.reported_by_display_name}</Link>
	}

	getMaterialAveragePrice(rowData) {
		return decode(rowData.material_average_cost_price);
	}

	getMaterialTotalPrice(rowData) {
		return decode(rowData.material_total_cost_price);
	}

	getStatus(rowData) {
		return <div className={"badge bg-planner-scrap-" + rowData.status_label.toLowerCase()}>{rowData.status_label}</div>;
	}

	getProduct(rowData) {
		return <>
			<Tooltip autoHide={false} target=".product_tooltip" />
			<div className="product_tooltip" data-pr-tooltip={rowData.product_description} data-pr-position="left"><Link to={"/product/" + rowData.product_id}>{rowData.product_sku}</Link></div>
		</>;
	}

	getOrder(rowData) {
		return <Link to={"/job/" + rowData.order_id}>{rowData.order_number}</Link>;
	}

	getDateReplaced(rowData) {
		return formatDate(rowData.date_replaced, true);
	}

	getScrapCatName(rowData) {
		let classColour = 'primary';

		if(rowData.scrap_cat_name === 'Uncategorised') {
			classColour = 'danger';
		}

		return <div className={"badge bg-" + classColour}>{rowData.scrap_cat_name}</div>
	}

	/**
	 * Toggle the expanded rows for the scraps
	 */
	toggleExpandRows() {
		let _expandedRows = {};

		if(this.state.expandedRows.length === 0) {
			this.state.orders.forEach((p) => (_expandedRows[`${p.id}`] = true));

			this.setState({
				expandedRows: _expandedRows
			});
		} else {
			this.setState({
				expandedRows: []
			});
		}
	}

	rowExpansionTemplate(rowData) {
		return <div className="px-5 py-3">{rowData.issue}</div>
	}

	render() {
		const { errorToast, isLoaded, expandedRows, filters, tableCount, visibleColumns } = this.state;

		let loadingClass = 'isLoading';

		if(isLoaded) {
			loadingClass = 'notLoading';
		}

		return (
			<>
				<Toast ref={errorToast} position="center" />
				<div className="edit-order-bar position-sticky top-0 py-3 mb-5" >
					<div className="mx-5 px-0 d-flex justify-content-between align-items-center w-100" style={{maxWidth: "100%"}}>
						<div className="d-flex">
							<div className="d-flex align-items-center">
								<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.updateSidebarOpened(true);}}>
									<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
								</Button>
							</div>
							<div>
								<h1 className="mb-0 h3">{this.getScrapTitle()}</h1>
								{
									(this.props.capabilities.edit_travellers || this.props.capabilities.read_travellers) && (<><strong>Total Scraps:</strong> {tableCount} of {this.state.scraps.length}</>)
								}
							</div>
						</div>
					</div>
				</div>
				{
					(this.props.capabilities.edit_travellers || this.props.capabilities.read_travellers) && ( // https://stackoverflow.com/questions/70682832/
						<div className="card mx-5 mb-5">
							{
								!isLoaded && (
									<div className={"mx-5 mb-3 d-flex align-items-center justify-content-center h-100 w-100"} style={{ position: 'absolute', zIndex: '99999' }}>
										<i className="pi pi-spin pi-spinner" style={{ fontSize: '6rem', height: '6rem' }}></i>
									</div>
								)
							}
							<DataTable className={loadingClass} expandedRows={expandedRows} rowExpansionTemplate={this.rowExpansionTemplate.bind(this)} onRowToggle={(e) => this.setState({expandedRows: e.data})} paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100]} onValueChange={filteredData => this.setState({tableCount: filteredData.length})} dataKey="id" size="small" sortOrder={-1} sortField="id" globalFilterFields={['material_sku', 'traveller_id', 'operator_name']} filters={filters} value={this.state.scraps} tableStyle={{ minWidth: '50rem' }} header={this.renderHeader(this)}>
								<Column expander={true} style={{ width: '3rem' }} /> 
								<Column key="scrap_number" field="scrap_number" header="Scrap #" sortable={true} />
								{visibleColumns.map((col) => {
									// Absolute shit code but LocalStorage won't store the body functions. See if there's a better way to do this.
									if(col.field === 'date') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getDate} />)
									} else if(col.field === 'traveller_number') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getTravellerNumber} />)
									} else if(col.field === 'material_sku') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getMaterial} />)
									} else if(col.field === 'operator_name') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getOperator} />)
									} else if(col.field === 'material_average_cost_price') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getMaterialAveragePrice} />)
									} else if(col.field === 'material_total_cost_price') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getMaterialTotalPrice} />)
									} else if(col.field === 'status_label') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getStatus} />)
									} else if(col.field === 'product_sku') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getProduct} />)
									} else if(col.field === 'order_number') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getOrder} />)
									} else if(col.field === 'date_replaced') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getDateReplaced} />)
									} else if(col.field === 'scrap_cat_name') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getScrapCatName} />)
									} else {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} />) // scrap_cat_name
									}
								})}
								<Column key="edit" field="edit" header="" body={this.getEditButton} style={{ minWidth: '6rem' }} />
							</DataTable>
						</div> 
					)
				}
			</>
		);
	}
}
export default Scraps